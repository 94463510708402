import React from 'react';
import { Menu, Dropdown, Space, Table, Button, Modal } from 'antd';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../Auth/AuthProvider';

const { confirm } = Modal;

function updateExamStatus(examList, examId, status, mutateFunction) {
  const updatedList = examList.map(singleExam => {
    if (singleExam.id === parseInt(examId, 10)) {
      return {
        ...singleExam,
        status,
      };
    }
    return singleExam;
  });

  mutateFunction({
    data: {
      exams: [...updatedList],
    },
  });
}

function updateExamTable(examList, examId, mutateFunction) {
  const updatedList = examList.filter(singleExam => {
    if (singleExam.id !== parseInt(examId, 10)) {
      return {
        ...singleExam,
      };
    }
    return singleExam;
  });

  mutateFunction({
    data: {
      exams: [...updatedList],
    },
  });
}

function ExamList(props) {
  const { exams, mutateAllExam } = props;
  const { MakeRequest } = useAuth();
  const history = useHistory();
  const examStatus = MakeRequest('POST', '/exam/status/update');

  const handleDelete = async id => {
    const examDelete = await MakeRequest('DELETE', `/delete/exam/${id}`);
    await examDelete(id);
    updateExamTable(exams, id, mutateAllExam);
    Modal.success({
      content: `Exam Deleted Successfully`,
    });
  };

  const showConfirm = id => {
    confirm({
      title: 'Sure to delete?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Everything associated with the exam (including enrolls and questions) will be deleted.',
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  const takeActions = async ({ key }) => {
    const [action, id] = key.split(',');

    switch (action) {
      case 'viewAndEdit':
        history.push(`/exam/${id}`);
        break;
      case 'publish':
        await examStatus({ status: 'published', exam_id: id });
        updateExamStatus(exams, id, 'published', mutateAllExam);
        break;
      case 'draft':
        await examStatus({ status: 'draft', exam_id: id });
        updateExamStatus(exams, id, 'draft', mutateAllExam);
        break;

      case 'enrolls':
        history.push(`/exam-enrolls/${id}`);
        break;

      case 'results':
        history.push(`/exam-results/${id}`);
        break;

      case 'delete':
        showConfirm(id);

        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'startDate',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },

    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'endDate',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            overlay={() => (
              <Menu onClick={takeActions}>
                <Menu.Item key={`viewAndEdit,${record.id}`}>View And Edit</Menu.Item>
                {record.status === 'draft' ? (
                  <Menu.Item key={`publish,${record.id}`}>Publish</Menu.Item>
                ) : (
                  <Menu.Item key={`draft,${record.id}`}>Draft</Menu.Item>
                )}
                {record.cost > 0 ? (
                  <Menu.Item key={`enrolls,${record.id}`}>Enrolls</Menu.Item>
                ) : null}
                {moment().isSameOrAfter(record.end_date) ? (
                  <Menu.Item key={`results,${record.id}`}>Results</Menu.Item>
                ) : null}
                <Menu.Item key={`delete,${record.id}`}>Delete</Menu.Item>
              </Menu>
            )}
          >
            <Button
              type="link"
              href="#"
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
            >
              Actions &nbsp;
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={exams}
        // loading={!exams.data}
        scroll={{ x: 360 }}
      />
    </>
  );
}

export default ExamList;
