/* eslint-disable react/jsx-indent */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Divider, Button, Card, Tabs } from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import AddTopic from '../components/AddTopic';
import TopicsList from './TopicsList';
import EditCurriculum from '../components/EditCurriculum';

const { TabPane } = Tabs;

function SingleCurriculumPage() {
  const { id } = useParams();
  const { MakeRequest, AuthState } = useAuth();
  const { role } = AuthState;
  const getCurriculumRequest = MakeRequest('GET', `/user/course/curriculum/show/${id}`);
  const history = useHistory();
  const { data: { data = {}, type } = {}, mutate: mutateCurriculum } = useSWR(
    `/user/course/curriculum/show/${id}`,
    getCurriculumRequest
  );

  const { curriculum = {} } = data;

  if (!type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (type === 'error') {
    return <AppLayout>Could not find the curriculum</AppLayout>;
  }

  return (
    <AppLayout>
      {role === 'admin' || role === 'instructor' ? (
        <>
          <Button
            type="primary"
            shape="round"
            htmlType="link"
            onClick={() => history.push(`/course/${curriculum.course_id}`)}
            className="switchBtnStyle"
          >
            Back To Course
          </Button>
          &nbsp;
          <AddTopic curriculum={curriculum} mutateCurriculum={mutateCurriculum} />
          &nbsp;
          <Row>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Divider orientation="left">
                Subject:&nbsp;
                {curriculum.title}
              </Divider>
              <Card
                title={`${curriculum.title} Details`}
                actions={[
                  <EditCurriculum curriculum={curriculum} mutateCurriculum={mutateCurriculum} />,
                ]}
                style={{ overflowWrap: 'break-word' }}
              >
                <h3>
                  Title: &nbsp;
                  <strong>{curriculum.title}</strong>
                </h3>
                <h3>
                  Description: &nbsp;
                  <strong>{curriculum.description}</strong>
                </h3>
                <h3>
                  Start Date: &nbsp;
                  <strong>{moment(curriculum.curriculum_date).format('Do MMM YY hh:mm A')}</strong>
                </h3>
                <h3>
                  Number Of Hours: &nbsp;
                  <strong>{curriculum.no_of_hours}</strong>
                </h3>
                <h3>
                  Instructor Assigned: &nbsp;
                  <strong>{curriculum.instructor.display_name}</strong>
                </h3>
                <h3>
                  Event Embed Code: &nbsp;
                  <strong>{curriculum.event_embed_code}</strong>
                </h3>
                <h3>
                  Chat Embed Code: &nbsp;
                  <strong>{curriculum.chat_embed_code}</strong>
                </h3>
                <h3>
                  Youtube Embed Code: &nbsp;
                  <strong>{curriculum.video}</strong>
                </h3>
              </Card>
              <>
                <Card title="Subject Video And Chat">
                  {/* <Col xs={24} sm={24} md={24} lg={15} xl={15}> */}
                  <div className="event-embed-container">
                    <div className="even-embed-iframe-div">
                      <iframe
                        title="test"
                        src={curriculum.event_embed_code}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        className="even-embed-iframe"
                      />
                    </div>
                  </div>
                  {/* </Col> */}
                  {/* <Col xs={24} sm={24} md={24} lg={9} xl={9}> */}
                  <div className="chat-embed-container">
                    <iframe
                      title="test"
                      src={curriculum.chat_embed_code}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                    />
                  </div>
                  {/* </Col> */}
                </Card>
              </>
            </Col>
            <Col xs={24} sm={24} md={16} lg={8} xl={8}>
              {curriculum.topics.length ? (
                <>
                  <TopicsList
                    topics={curriculum.topics}
                    role={role}
                    mutateCurriculum={mutateCurriculum}
                  />
                </>
              ) : (
                <Divider orientation="left">
                  No Topics found in &nbsp;
                  {curriculum.title}
                </Divider>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Divider orientation="left">
            Subject:&nbsp;
            {curriculum.title}
          </Divider>
        </>
      )}

      {role === 'student' ? (
        <div className="card-container">
          <Tabs type="card">
            <TabPane tab="Video" key="1">
              <Row gutter={[16, 8]} style={{ margin: '0px 0px 0px' }}>
                <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                  <div className="event-embed-container">
                    <div className="even-embed-iframe-div">
                      <iframe
                        title="test"
                        src={curriculum.event_embed_code}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        className="even-embed-iframe"
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                  <div className="chat-embed-container">
                    <iframe
                      title="test"
                      src={curriculum.chat_embed_code}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Topics To Cover" key="2">
              {curriculum.topics.length
                ? curriculum.topics.map(singleTopic => {
                    return (
                      <Button onClick={() => history.push(`/topic/${singleTopic.id}`)}>
                        {singleTopic.title}
                      </Button>
                    );
                  })
                : null}
            </TabPane>
          </Tabs>
        </div>
      ) : null}
    </AppLayout>
  );
}

export default SingleCurriculumPage;
