import { Button, Card, Col, Divider, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from './Auth/AuthProvider';
import CreateNewQuestionsForm from './CreateNewQuestionsForm';
import EditExam from './EditExam';
import QuestionsList from './QuestionsList';

function AdminExam(props) {
  const { exam, mutateExam } = props;
  const history = useHistory();
  const { AuthState } = useAuth();
  const { role } = AuthState;

  return (
    <>
      <Button
        type="primary"
        shape="round"
        htmlType="link"
        onClick={() => history.push(`/manage-exams`)}
        className="switchBtnStyle"
      >
        Back To Exam List
      </Button>
      &nbsp;
      <>
        {exam.questions.length ? (
          <CreateNewQuestionsForm
            exam={exam}
            mutateExam={mutateExam}
            buttonText="Add More Questions"
          />
        ) : (
          <CreateNewQuestionsForm
            exam={exam}
            mutateExam={mutateExam}
            buttonText="Create Questions"
          />
        )}
      </>
      <Row>
        <Col xs={24} sm={24} md={16} lg={8} xl={8}>
          <Divider orientation="left">
            Exam:&nbsp;
            {exam.title}
          </Divider>
          <Card
            title={`${exam.title} Details`}
            actions={[<EditExam exam={exam} mutateExam={mutateExam} />]}
            style={{ overflowWrap: 'break-word' }}
          >
            {/* {exam.image_url ? <Image width={200} src={exam.image_url} /> : null} */}
            <h3>
              Title: &nbsp;
              <strong>{exam.title}</strong>
            </h3>

            <h3>
              Starts On: &nbsp;
              <strong>{moment(exam.start_date).format('Do MMM YY hh:mm A')}</strong>
            </h3>
            <h3>
              Ends On: &nbsp;
              <strong>{moment(exam.end_date).format('Do MMM YY hh:mm A')}</strong>
            </h3>
            <h3>
              Negative Mark: &nbsp;
              <strong>{exam.negative_mark}</strong>
            </h3>

            <h3>
              Cost: &nbsp;
              <strong>
                {exam.cost}
                &nbsp;Rs
              </strong>
            </h3>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <QuestionsList exam={exam} role={role} mutateExam={mutateExam} />
        </Col>
      </Row>
    </>
  );
}

export default AdminExam;
