/* eslint-disable no-nested-ternary */
import {
  Button,
  Input,
  Form,
  DatePicker,
  InputNumber,
  Result,
  Col,
  Row,
  Select,
  Modal,
} from 'antd';
import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import notify from '../utils/Notify';
import { useAuth } from './Auth/AuthProvider';

function EditCurriculum(props) {
  const { curriculum, mutateCurriculum } = props;

  const { MakeRequest, AuthState } = useAuth();
  const [isProcessing, setIsProcessing] = React.useState(false);

  const { role } = AuthState;

  const instructorsRequest = MakeRequest('GET', '/instructors');

  const { data: instructor = {} } = useSWR('/instructors', instructorsRequest);
  const { data: { instructors = [] } = {} } = instructor;

  const [updateEducator, onUpdateEducator] = React.useState(null);

  const onValuesChange = e => {
    if (e.educator) {
      onUpdateEducator(e.educator);
    }
  };

  const onFinish = async editCurriculumData => {
    setIsProcessing(true);

    const editCurriculum = MakeRequest('POST', `/curriculum/update/${curriculum.id}`);
    const {
      title,
      duration,
      curriculumDate,
      description,
      video,
      streamembedcode,
      chatembedcode,
    } = editCurriculumData;
    const formattedData = {
      title,
      no_of_hours: duration,
      curriculum_date: curriculumDate.add(330, 'minutes').format(),
      description,
      course_id: curriculum.course_id,
      video,
      event_embed_code: streamembedcode,
      chat_embed_code: chatembedcode,
      instructor_id: updateEducator || curriculum.instructor_id,
    };
    const response = await editCurriculum(formattedData);
    if (response.errors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [error] of Object.values(response.data)) {
        notify({ type: 'error', message: error });
      }
      setIsProcessing(false);
    } else if (response.type === 'success') {
      const updatedCurriculum = {
        title,
        no_of_hours: duration,
        curriculum_date: curriculumDate.add(330, 'minutes').format(),
        description,
        course_id: curriculum.course_id,
        video,
        event_embed_code: streamembedcode,
        chat_embed_code: chatembedcode,
        instructor_id: updateEducator || curriculum.instructor_id,
        topics: curriculum.topics,
      };
      mutateCurriculum({ ...curriculum, updatedCurriculum });

      Modal.success({
        content: `${title} Updated`,
      });
    }
  };

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setIsProcessing(false);
  };

  return (
    <>
      <Button
        variant="contained"
        className="syllabusBtnStyle"
        color="primary"
        shape="round"
        onClick={handleOpen}
      >
        Edit Subject
      </Button>
      <Modal title="Edit Syllabus Subject" visible={visible} onCancel={handleClose} footer={null}>
        {isProcessing === 'success' ? (
          <Result status="success" title="Updated Syllabus" />
        ) : curriculum ? (
          <Form
            layout="vertical"
            name="addNewCurriculum"
            onFinish={onFinish}
            initialValues={{
              title: curriculum.title,
              description: curriculum.description,
              curriculumDate: moment(curriculum.curriculum_date),
              duration: curriculum.no_of_hours,
              video: curriculum.video,
              educator: curriculum.instructor.display_name,
              streamembedcode: curriculum.event_embed_code,
              chatembedcode: curriculum.chat_embed_code,
            }}
            onValuesChange={onValuesChange}
          >
            <Form.Item name="title" label="Subject Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="description"
                  label="Subject Description"
                  rules={[
                    { required: true },

                    () => ({
                      validator(_, value) {
                        if (!value || (value.length >= 4 && value.length <= 200)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Description should be 4 to 200 chracters')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>
                <Form.Item
                  name="curriculumDate"
                  label="Subject Start Date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
                <Form.Item
                  name="duration"
                  label="Subject Duration (hrs)"
                  rules={[
                    {
                      type: 'number',
                      min: 1,
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>

                {role === 'admin' ? (
                  instructors.length ? (
                    <Form.Item name="educator" label="Educator">
                      <Select>
                        {instructors.map(singleInstructor => (
                          <Select.Option key={singleInstructor.id} value={singleInstructor.id}>
                            {singleInstructor.display_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                ) : null}
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="streamembedcode" label="Stream Embed Code">
                  <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item name="chatembedcode" label="Chat Embed Code">
                  <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item name="video" label="Youtube Embed Code">
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );
}

export default EditCurriculum;
