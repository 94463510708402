import Axios from 'axios';

Axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

export async function UserListRequest(token) {
  const UserListDataRequest = Axios.create();

  try {
    const UserListDataResponse = await UserListDataRequest({
      method: 'GET',
      url: `/users`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return { type: 'success', data: UserListDataResponse.data.users };
  } catch (error) {
    if (error.response) {
      return { type: 'error', data: error.response.data.errors };
    }
    return { type: 'error', data: error };
  }
}

// add new user
export async function AddNewUser(userData, token) {
  const { fullname, email, phone, password, confirm } = userData;

  const UserDataRequest = Axios.create();

  const formattedData = {
    display_name: fullname,
    email,
    phone_number: phone,
    password,
    password_confirmation: confirm,
  };

  try {
    const UserDataResponse = await UserDataRequest({
      method: 'POST',
      url: `/user/create`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(formattedData),
    });
    return { type: 'success', data: UserDataResponse.data };
  } catch (error) {
    if (error.response) {
      return { type: 'error', data: error.response.data.errors };
    }
    return { type: 'error', data: { error: ['Unknown error'] } };
  }
}
