import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppLayout from '../components/AppLayout';
import UpdateProfile from '../components/UpdateProfile';

function CreateSettingsPage() {
  return (
    <AppLayout>
      <Helmet>
        <title>Manage settings</title>
      </Helmet>
      <UpdateProfile />
    </AppLayout>
  );
}

export default CreateSettingsPage;
