import React, { useState } from 'react';
import { Form, Input, Checkbox, Button, Result, Divider } from 'antd';
import { Register } from '../utils/AuthRequests';
import notify from '../utils/Notify';

const RegistrationForm = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const onFinish = async registrationData => {
    setIsProcessing(true);
    setUserEmail(registrationData.email);
    const response = await Register(registrationData);
    if (response.type === 'error') {
      // eslint-disable-next-line no-restricted-syntax
      for (const [error] of Object.values(response.data)) {
        notify({ type: 'error', message: error });
      }
      setIsProcessing(false);
    } else if (response.type === 'success') {
      setIsProcessing('success');
    }
  };

  if (isProcessing === 'success') {
    return (
      <Result
        status="success"
        title="Verification Email has been sent"
        subTitle={`Please check ${userEmail}'s inbox for verification email`}
      />
    );
  }

  return (
    <Form layout="vertical" name="register" onFinish={onFinish} scrollToFirstError>
      <Divider orientation="left">Register new account</Divider>

      <Form.Item
        name="fullname"
        label={<span>Fullname</span>}
        rules={[
          {
            required: true,
            message: 'Please enter your fullname',
            whitespace: true,
          },
          () => ({
            validator(_, value) {
              if (!value || (value.length >= 4 && value.length <= 25)) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('Fullname should be 4 to 25 characters'));
            },
          }),
        ]}
      >
        <Input maxLength={25} />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid E-mail ID',
          },
          {
            required: true,
            message: 'Please enter your E-mail ID',
          },
        ]}
      >
        <Input name="email" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          {
            required: true,
            message: 'Please enter your Phone Number',
          },
          () => ({
            validator(_, value) {
              if (!value || (value.length === 10 && !Number.isNaN(value))) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('Phone Number should be of 10 digits'));
            },
          }),
        ]}
      >
        <Input name="phone" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please enter a password',
          },
          () => ({
            validator(_, value) {
              if (!value || value.length >= 8) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('Password should be more than 8 characters'));
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error('Terms and Conditions must be agreed')),
          },
        ]}
      >
        <Checkbox>
          Agree to &nbsp;
          <a href="#terms">Terms and Conditions</a>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button disabled={isProcessing} type="primary" shape="round" size="large" htmlType="submit">
          REGISTER
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegistrationForm;
