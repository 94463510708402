import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Divider, Tabs, Button } from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import axios from 'axios';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import AdminCourse from '../components/AdminCourse';
import CurriculumList from '../components/CurriculumList';

const { TabPane } = Tabs;

function SingleCoursePage() {
  const { id } = useParams();
  const { MakeRequest, AuthState } = useAuth();
  const { role, token } = AuthState;
  const getCourseRequest = MakeRequest('GET', `/user/course/show/${id}`);

  const { data: { data = {}, type } = {}, mutate: mutateCourse } = useSWR(
    `/user/course/show/${id}`,
    getCourseRequest
  );

  const { course = {} } = data;

  if (!type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (type === 'error') {
    return <AppLayout>Could not find the course</AppLayout>;
  }

  const downloadSyllabus = async (fileType, orderId, authToken) => {
    const URL = process.env.REACT_APP_BACKEND_API_PATH;
    const formdata = {
      orderId,
      file_type: fileType,
    };

    try {
      const response = await axios({
        url: `${URL}/api/download/asset`,
        method: 'POST',

        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        responseType: 'blob',

        data: JSON.stringify(formdata),
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileType}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, 'ERROR');
    }
  };

  return (
    <AppLayout>
      {role === 'admin' || role === 'instructor' ? (
        <AdminCourse course={course} mutateCourse={mutateCourse} />
      ) : (
        <>
          <Divider orientation="left">
            Course:&nbsp;
            {course.title}
          </Divider>
          <div className="card-container">
            <Tabs type="card">
              <TabPane tab="Video" key="1">
                <Row gutter={[16, 8]}>
                  {moment().isSameOrAfter(course.start_date) ? (
                    <>
                      <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                        <div className="event-embed-container">
                          <div className="even-embed-iframe-div">
                            <iframe
                              title="test"
                              src={course.event_embed_code}
                              frameBorder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowFullScreen
                              className="even-embed-iframe"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                        <div className="chat-embed-container">
                          <iframe
                            title="test"
                            src={course.chat_embed_code}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <iframe
                          width="100%"
                          height="400px"
                          src={course.youtube_embed_code}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={8}
                        xl={8}
                        style={{ overflowWrap: 'break-word' }}
                      >
                        <h3>
                          Starts on: &nbsp;
                          {moment(course.start_date).format('Do MMM YY hh:mm A')}
                        </h3>
                        <h3>
                          Duration: &nbsp;
                          {course.no_of_days}
                          &nbsp; hrs
                        </h3>

                        <h3>
                          Description: &nbsp;
                          {course.description}
                        </h3>
                      </Col>
                    </>
                  )}
                </Row>
              </TabPane>
              <TabPane tab="Syllabus" key="2">
                {course.curriculums.length ? (
                  <CurriculumList course={course} />
                ) : (
                  <Divider orientation="left">No Syllabus found</Divider>
                )}
              </TabPane>
              <TabPane tab="Downloads" key="3">
                {course.syllabus ? (
                  <>
                    <Divider orientation="left">Syllabus Downloads </Divider>

                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => downloadSyllabus('syllabus', course.id, token)}
                    >
                      Download Syllabus for&nbsp;
                      {course.title}
                    </Button>
                  </>
                ) : (
                  <Divider orientation="left">Syllabus Downloads Coming Soon</Divider>
                )}

                {course.previous_question_papers ? (
                  <>
                    <Divider orientation="left">Previous Question Paper Downloads </Divider>

                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => downloadSyllabus('previous_question_papers', course.id, token)}
                    >
                      Download Previous Question Papers for&nbsp;
                      {course.title}
                    </Button>
                  </>
                ) : (
                  <Divider orientation="left">
                    Previous Question Papers Downloads Coming Soon
                  </Divider>
                )}
              </TabPane>
            </Tabs>
          </div>
        </>
      )}
    </AppLayout>
  );
}

export default SingleCoursePage;
