import { BookOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Statistic } from 'antd';
import React from 'react';
import useSWR from 'swr';
import '../../App.css';
import { useAuth } from '../Auth/AuthProvider';

function AdminDashboard() {
  const { MakeRequest } = useAuth();

  const CountRequest = MakeRequest('GET', '/instructor/dashboard/counts');
  const { data: res = {} } = useSWR('/instructor/dashboard/counts', CountRequest);

  const {
    data: {
      totalCourses,
      totalDraftCourses,
      totalDraftExams,
      totalExams,
      totalFreeCourses,
      totalFreeExams,
      totalPremiumCourses,
      totalPremiumExams,
      totalPublishedCourses,
      totalPublishedExams,
    } = {},
  } = res;

  return (
    <>
      <div>
        <Divider orientation="left">Dashboard</Divider>
        <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
          <Col className="dashboard-col-style" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card>
              <Statistic title="Total Courses" value={totalCourses} prefix={<DatabaseOutlined />} />
              <Card type="inner">
                <Statistic title="Premium" value={totalPremiumCourses} />
                <Statistic title="Free" value={totalFreeCourses} />
                <Statistic title="Published" value={totalPublishedCourses} />
                <Statistic title="Draft" value={totalDraftCourses} />
              </Card>
            </Card>
          </Col>
          <Col className="dashboard-col-style" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card>
              <Statistic title="Total Exams" value={totalExams} prefix={<BookOutlined />} />
              <Card type="inner">
                <Statistic title="Premium" value={totalPremiumExams} />
                <Statistic title="Free" value={totalFreeExams} />
                <Statistic title="Published" value={totalPublishedExams} />
                <Statistic title="Draft" value={totalDraftExams} />
              </Card>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdminDashboard;
