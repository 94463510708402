import React from 'react';
import { Table, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function StudentCourseList(props) {
  const { courses } = props;

  const history = useHistory();

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'Educator',
      dataIndex: 'instructor',
      render: instructor => {
        return instructor.display_name;
      },
    },

    {
      title: 'Duration(Hrs)',
      dataIndex: 'no_of_days',
      key: 'duration',
    },

    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'Sdate',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        return <Button onClick={() => history.push(`/course/${record.id}`)}>Play</Button>;
      },
    },
  ];

  return (
    <>
      {/* <Divider orientation="center" plain>
        Click (+) button on the left to view syllabus for the course
      </Divider> */}

      <Table
        columns={columns}
        rowKey="id"
        dataSource={courses}
        loading={courses.length}
        scroll={{ x: 360 }}
        // expandable={{
        //   expandedRowRender: record => {
        //     return (
        //       <>
        //         {record.curriculums.length ? (
        //           <CurriculumList curriculums={record.curriculums} />
        //         ) : (
        //           <Divider orientation="left">No Syllabus found</Divider>
        //         )}
        //       </>
        //     );
        //   },
        // }}
      />
    </>
  );
}

export default StudentCourseList;
