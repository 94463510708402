import React from 'react';
import { Avatar, Space } from 'antd';
import { Link } from 'react-router-dom';

function UserAvatarName(props) {
  const { displayName } = props;
  return (
    <div className="user-avatar-name">
      <Space size="small">
        <Avatar shape="circle" size="medium">
          {displayName.charAt(0).toUpperCase()}
        </Avatar>
        <Link to="/" className="ant-dropdown-link">
          {displayName}
        </Link>
      </Space>
    </div>
  );
}

export default UserAvatarName;
