import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Input, Button, Result, Divider } from 'antd';
import notify from '../utils/Notify';
import { ResetPassword } from '../utils/AuthRequests';

const ResetPasswordForm = () => {
  const { email, token } = useParams();

  const [isProcessing, setIsProcessing] = useState(false);

  const onFinish = async resetPasswordData => {
    setIsProcessing(true);
    const response = await ResetPassword(resetPasswordData, email, token);
    if (response.type === 'error') {
      notify({ type: 'error', message: response.data });
      setIsProcessing(false);
    } else if (response.type === 'success') {
      setIsProcessing('success');
    }
  };

  if (isProcessing === 'success') {
    return (
      <Result
        status="success"
        title="Your password reset was successful"
        extra={[
          <Link key="Login" className="ant-btn ant-btn-primary" to="/login">
            Login
          </Link>,
        ]}
      />
    );
  }

  return (
    <Form layout="vertical" name="resetPassword" onFinish={onFinish} scrollToFirstError>
      <Divider orientation="left">Reset your Password</Divider>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please enter a password',
          },
          () => ({
            validator(_, value) {
              if (!value || value.length >= 8) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('Password should be more than 8 characters'));
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button disabled={isProcessing} type="primary" shape="round" size="large" htmlType="submit">
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ResetPasswordForm;
