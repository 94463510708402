import { Button, Card, Col, Divider, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AddCurriculum from './AddCurriculum';
import { useAuth } from './Auth/AuthProvider';
import CurriculumList from './CurriculumList';
import EditClass from './EditClass';

function AdminCourse(props) {
  const { course, mutateCourse } = props;
  const history = useHistory();
  const { AuthState } = useAuth();
  const { role } = AuthState;

  return (
    <>
      <Button
        type="primary"
        shape="round"
        htmlType="link"
        onClick={() => history.push(`/manage-classes`)}
        className="switchBtnStyle"
      >
        Back To Course List
      </Button>
      &nbsp;
      <>
        {course.curriculums.length ? (
          <AddCurriculum
            course={course}
            mutateCourse={mutateCourse}
            buttonText="Add New Syllabus Subject"
          />
        ) : (
          <AddCurriculum
            course={course}
            mutateCourse={mutateCourse}
            buttonText="Create A Syllabus"
          />
        )}
      </>
      <Row>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Divider orientation="left">
            Course:&nbsp;
            {course.title}
          </Divider>
          <Card
            title={`${course.title} Details`}
            actions={[<EditClass course={course} mutateCourse={mutateCourse} />]}
            style={{ overflowWrap: 'break-word' }}
          >
            <h3>
              Title: &nbsp;
              <strong>{course.title}</strong>
            </h3>
            <h3>
              Description: &nbsp;
              <strong>{course.description}</strong>
            </h3>
            <h3>
              Start Date: &nbsp;
              <strong>{moment(course.start_date).format('Do MMM YY hh:mm A')}</strong>
            </h3>
            <h3>
              Number Of Days: &nbsp;
              <strong>{course.no_of_days}</strong>
            </h3>
            <h3>
              Educator: &nbsp;
              <strong>{course.instructor.display_name}</strong>
            </h3>
            <h3>
              Event Embed Code: &nbsp;
              <strong>{course.event_embed_code}</strong>
            </h3>
            <h3>
              Chat Embed Code: &nbsp;
              <strong>{course.chat_embed_code}</strong>
            </h3>
            <h3>
              Youtube Embed Code: &nbsp;
              <strong>{course.youtube_embed_code}</strong>
            </h3>
            <h3>
              Cost: &nbsp;
              <strong>
                {course.cost}
                &nbsp;Rs
              </strong>
            </h3>
          </Card>
          <>
            <Card title="Course Video And Chat">
              {/* <Col xs={24} sm={24} md={24} lg={15} xl={15}> */}
              <div className="event-embed-container">
                <div className="even-embed-iframe-div">
                  <iframe
                    title="test"
                    src={course.event_embed_code}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    className="even-embed-iframe"
                  />
                </div>
              </div>
              {/* </Col> */}
              {/* <Col xs={24} sm={24} md={24} lg={9} xl={9}> */}
              <div className="chat-embed-container">
                <iframe
                  title="test"
                  src={course.chat_embed_code}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                />
              </div>
              {/* </Col> */}
            </Card>
          </>
        </Col>
        <Col xs={24} sm={24} md={16} lg={8} xl={8}>
          <CurriculumList course={course} role={role} mutateCourse={mutateCourse} />
        </Col>
      </Row>
    </>
  );
}

export default AdminCourse;
