/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Menu, Dropdown, Space, Table, Button, Divider } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import CreateNewEnrollForm from '../components/CreateNewEnrollForm';

function updatedEnrollStatus(course, enrollId, access, mutateFunction) {
  const updatedList = course.enrolls.map(singleEnroll => {
    if (singleEnroll.id === parseInt(enrollId, 10)) {
      return {
        ...singleEnroll,
        access,
      };
    }

    return singleEnroll;
  });
  mutateFunction({
    data: {
      course: [...updatedList],
    },
  });
}

function CourseEnrollsPage() {
  const { id } = useParams();
  const { MakeRequest, AuthState } = useAuth();
  const { role } = AuthState;

  const CourseListRequest = MakeRequest('GET', `/course/enrolls/${id}`);
  const { data: res = {}, mutate: mutateAllEnrolls } = useSWR(
    `/course/enrolls/${id}`,
    CourseListRequest
  );
  const { data: { course = {} } = {} } = res;

  if (!res.type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (res.type === 'error') {
    return <AppLayout>Could not find the course</AppLayout>;
  }
  const enrollStatus = MakeRequest('POST', '/enroll/access/status/update');

  const takeActions = async ({ key }) => {
    const [action, enrollId] = key.split(',');

    switch (action) {
      case 'grant':
        await enrollStatus({ access: 'granted', enroll_id: enrollId });
        updatedEnrollStatus(course, enrollId, 'granted', mutateAllEnrolls);
        break;
      case 'block':
        await enrollStatus({ access: 'blocked', enroll_id: enrollId });
        updatedEnrollStatus(course, enrollId, 'blocked', mutateAllEnrolls);
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'user',
      render: user => {
        return user.display_name;
      },
    },

    {
      title: 'Email',
      dataIndex: 'user',
      render: user => {
        return user.email;
      },
    },

    {
      title: 'Transaction Id',
      dataIndex: 'payment',
      render: payment => {
        return payment.transaction_id;
      },
    },
    {
      title: 'Paid Amount(Rs)',
      dataIndex: 'payment',
      render: payment => {
        return payment.amount;
      },
    },

    {
      title: 'Enrolled Date',
      dataIndex: 'created_at',
      key: 'Edate',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },

    {
      title: 'Access Status',
      dataIndex: 'access',
      key: 'access',
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            overlay={() => (
              <>
                <Menu onClick={takeActions}>
                  {record.access === 'granted' || record.access === null ? (
                    <Menu.Item key={`block,${record.id}`}>Block Access</Menu.Item>
                  ) : (
                    <Menu.Item key={`grant,${record.id}`}>Grant Access</Menu.Item>
                  )}
                </Menu>
              </>
            )}
          >
            <Button
              type="link"
              href="#"
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
            >
              Actions &nbsp;
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <AppLayout>
      {role === 'admin' || role === 'instructor' ? (
        <>
          <CreateNewEnrollForm
            course={course}
            mutateAllEnrolls={mutateAllEnrolls}
            enrolls={course.enrolls}
          />
          <Divider orientation="left">Course: {course.title}</Divider>
          <Divider orientation="left">Enrolled Students</Divider>
          <Table columns={columns} rowKey="id" dataSource={course.enrolls} scroll={{ x: 360 }} />
        </>
      ) : (
        <Divider orientation="left">Not Authorized</Divider>
      )}
    </AppLayout>
  );
}

export default CourseEnrollsPage;
