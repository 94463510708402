/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Form,
  Select,
  InputNumber,
  DatePicker,
  Button,
  Upload,
  Input,
  Row,
  Col,
  Modal,
} from 'antd';
import useSWR from 'swr';
import { useAuth } from './Auth/AuthProvider';
import { AddNewFreeClass } from '../utils/CourseRequests';
import notify from '../utils/Notify';

const URL = process.env.REACT_APP_BACKEND_API_PATH;

const CreateNewFreeClassForm = props => {
  const { courses, mutateAllCourse } = props;
  // const [isProcessing, setIsProcessing] = useState(false);
  // const [addNewFreeClass, setAddNewFreeClass] = useState('');
  const { AuthState, MakeRequest } = useAuth();
  const { token, displayName, role } = AuthState;

  const instructorsRequest = MakeRequest('GET', '/instructors');

  const { data: instructor = {} } = useSWR('/instructors', instructorsRequest);
  const { data: { instructors = [] } = {} } = instructor;

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    // setIsProcessing(false);
  };

  const onFinish = async freeClassData => {
    // setIsProcessing(true);
    // setAddNewFreeClass(freeClassData.title);
    const response = await AddNewFreeClass(freeClassData, token);
    if (response.type === 'error') {
      // eslint-disable-next-line no-restricted-syntax
      for (const [error] of Object.values(response.data)) {
        notify({ type: 'error', message: error });
      }
      // setIsProcessing(false);
    } else if (response.type === 'success') {
      const courseToBeAdded = {
        id: Math.random(),
        created_at: new Date(),
        updated_at: new Date(),
        title: freeClassData.title,
        start_date: freeClassData.startdate.format(),
        no_of_days: freeClassData.duration,
        instructor_id: freeClassData.educator,
        description: freeClassData.description,
        // cost: '',
        syllabus: freeClassData.uploadSyllabus,
        previous_question_papers: freeClassData.uploadPreviousQNPapers,
        event_embed_code: freeClassData.streamembedcode,
        chat_embed_code: freeClassData.chatembedcode,
        youtube_embed_code: freeClassData.ytembedcode,
        // status,
        curriculums: null,
        instructor: {
          id: Math.random(),
          display_name: displayName,
          email: null,
          email_verified_at: new Date(),
          role,
          created_at: new Date(),
          updated_at: new Date(),
        },
      };

      mutateAllCourse({
        data: {
          courses: [courseToBeAdded, ...courses],
        },
      });
      handleClose();

      Modal.success({
        content: `New Course ${freeClassData.title} Created`,
      });
    }
  };

  const deleteAsset = async fileObject => {
    const { response } = fileObject;
    const deleteAssetRequest = MakeRequest('DELETE', '/delete/asset');
    await deleteAssetRequest({
      url: response.url,
    });
  };

  return (
    <>
      <Button
        variant="contained"
        className="btnStyle"
        shape="round"
        color="primary"
        onClick={handleOpen}
      >
        Create New Course
      </Button>
      <Modal title="Create New Course" visible={visible} onCancel={handleClose} footer={null}>
        <Form layout="vertical" name="addnewclass" onFinish={onFinish} scrollToFirstError>
          <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="title"
                label={<span>Title</span>}
                rules={[
                  {
                    required: true,
                    message: 'Please enter class title',
                    whitespace: true,
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value.length >= 4) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Title should be more than 2 chracters'));
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="description" label="Description">
                <Input.TextArea rows={5} />
              </Form.Item>

              <Form.Item
                name="duration"
                label="Duration (hrs)"
                rules={[
                  {
                    type: 'number',
                    min: 1,
                    required: true,
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>

              {role === 'admin' ? (
                instructors.length ? (
                  <Form.Item name="educator" label="Educator">
                    <Select>
                      {instructors.map(singleInstructor => (
                        <Select.Option key={singleInstructor.id} value={singleInstructor.id}>
                          {singleInstructor.display_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : null
              ) : null}

              <Form.Item
                name="startdate"
                label="Start Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker showTime />
              </Form.Item>

              <Input.Group size="large">
                <Row gutter={0}>
                  <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <Form.Item
                      name="uploadSyllabus"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        style={{ width: '300px' }}
                        onRemove={deleteAsset}
                        action={`${URL}/api/upload/asset`}
                        name="file"
                        listType="all"
                        headers={{
                          Authorization: `Bearer ${token}`,
                        }}
                        data={{ file_type: 'syllabus' }}
                      >
                        <Button>Upload Syllabus</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <Form.Item
                      name="uploadPreviousQNPapers"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        onRemove={deleteAsset}
                        action={`${URL}/api/upload/asset`}
                        name="file"
                        listType="all"
                        headers={{
                          Authorization: `Bearer ${token}`,
                        }}
                        data={{ file_type: 'questionPapers' }}
                      >
                        <Button>Previous Qn Papers</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item name="streamembedcode" label="Stream Embed Code">
                <Input.TextArea rows={5} />
              </Form.Item>

              <Form.Item name="chatembedcode" label="Chat Embed Code">
                <Input.TextArea rows={5} />
              </Form.Item>

              <Form.Item name="ytembedcode" label="Youtube Embed Code">
                <Input.TextArea rows={5} />
              </Form.Item>

              {/* <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Select.Option value="1">Draft</Select.Option>
              <Select.Option value="2">Publish</Select.Option>
            </Select>
          </Form.Item> */}

              <Form.Item
                name="cost"
                label={<span>Course Cost</span>}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value || value <= 10000) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Cost should be less than or equal to 10,000 Rs')
                      );
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" shape="round" size="large" htmlType="submit">
                  Add Course
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateNewFreeClassForm;
