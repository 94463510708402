import notify from './Notify';

const errorHandler = (err, history, next = () => {}) => {
  if (err.response) {
    switch (err.response.statusText) {
      case 'Unauthorized':
        notify({
          type: 'error',
          message: 'Token expired',
          description: 'Please login again',
        });
        setTimeout(() => {
          history.push('/logout');
        }, 5000);
        break;

      default:
        break;
    }
  }

  next(err);
};

// eslint-disable-next-line import/prefer-default-export
export { errorHandler };
