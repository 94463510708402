/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Form,
  Select,
  InputNumber,
  DatePicker,
  Button,
  Input,
  Row,
  Col,
  Result,
  Modal,
} from 'antd';
import moment from 'moment';
import useSWR from 'swr';
import { useAuth } from './Auth/AuthProvider';
import notify from '../utils/Notify';

const EditClass = props => {
  const { course, mutateCourse } = props;
  const { AuthState, MakeRequest } = useAuth();
  const { role } = AuthState;
  const [isProcessing, setIsProcessing] = React.useState(false);

  const instructorsRequest = MakeRequest('GET', '/instructors');

  const { data: instructor = {} } = useSWR('/instructors', instructorsRequest);
  const { data: { instructors = [] } = {} } = instructor;
  const [updateEducator, onUpdateEducator] = React.useState(null);

  const onValuesChange = e => {
    if (e.educator) {
      onUpdateEducator(e.educator);
    }
  };
  const onFinish = async editClassData => {
    setIsProcessing(true);
    const editClass = MakeRequest('POST', `/course/update/${course.id}`);
    const {
      title,
      description,
      duration,
      streamembedcode,
      chatembedcode,
      ytembedcode,
      cost,
      // educator,
      startdate,
    } = editClassData;
    const formattedData = {
      title,
      description,
      no_of_days: duration,
      event_embed_code: streamembedcode,
      chat_embed_code: chatembedcode,
      youtube_embed_code: ytembedcode,
      cost,
      syllabus: course.syllabus,
      previous_question_papers: course.previous_question_papers,
      instructor_id: updateEducator || course.instructor_id,
      start_date: startdate.add(330, 'minutes').format(),
      status: course.status,
    };
    // setIsProcessing(true);
    // setIsProcessing('success');

    const response = await editClass(formattedData);
    if (response.type === 'error') {
      // eslint-disable-next-line no-restricted-syntax
      // for (const [error] of Object.values(response.data)) {
      notify({ type: 'error', message: 'error, check if the title is unique' });
      // }
      setIsProcessing(false);
    } else if (response.type === 'success') {
      setIsProcessing(false);

      const updatedCourse = {
        chat_embed_code: editClassData.chatembedcode,
        cost: editClassData.cost,
        created_at: course.created_at,
        curriculums: course.curriculums,
        deleted_at: course.deleted_at,
        description: editClassData.description,
        enrolls: [],
        event_embed_code: editClassData.event_embed_code,
        id: course.id,
        instructor: course.instructor,
        instructor_id: updateEducator || course.instructor_id,
        no_of_days: editClassData.duration,
        previous_question_papers: null,
        start_date: editClassData.startdate.add(330, 'minutes').format(),
        status: course.status,
        syllabus: null,
        title: editClassData.title,
        updated_at: course.updated_at,
        youtube_embed_code: editClassData.ytembedcode,
      };

      mutateCourse({ ...course, updatedCourse });
      Modal.success({
        content: `Updated Course${course.title}`,
      });
    }
  };

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setIsProcessing(false);
  };

  return (
    <>
      <Button
        variant="contained"
        className="btnStyle"
        shape="round"
        color="primary"
        onClick={handleOpen}
      >
        Edit Course
      </Button>
      <Modal title="Edit Course" visible={visible} onCancel={handleClose} footer={null}>
        {isProcessing === 'success' ? (
          <Result status="success" title="Updated Course" />
        ) : course.id ? (
          <Form
            layout="vertical"
            name="editclass"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={{
              title: course.title,
              description: course.description,
              duration: course.no_of_days,
              streamembedcode: course.event_embed_code,
              chatembedcode: course.chat_embed_code,
              ytembedcode: course.youtube_embed_code,
              cost: course.cost,
              educator: course.instructor.display_name,
              startdate: moment(course.start_date),
            }}
            scrollToFirstError
          >
            <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="title"
                  label={<span>Title</span>}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter class title',
                      whitespace: true,
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || (value.length >= 4 && value.length <= 40)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('Title should be 4 to 40 chracters'));
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required: true },

                    () => ({
                      validator(_, value) {
                        if (!value || (value.length >= 4 && value.length <= 200)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Description should be 4 to 200 chracters')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item
                  name="duration"
                  label="Duration (hrs)"
                  rules={[
                    {
                      type: 'number',
                      min: 1,
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>

                {role === 'admin' ? (
                  instructors.length ? (
                    <Form.Item name="educator" label="Educator">
                      <Select>
                        {instructors.map(singleInstructor => (
                          <Select.Option key={singleInstructor.id} value={singleInstructor.id}>
                            {singleInstructor.display_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                ) : null}

                <Form.Item
                  name="startdate"
                  label="Start Date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker showTime />
                </Form.Item>

                {/* <Input.Group size="large">
            <Row gutter={0}>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Form.Item
                  name="uploadSyllabus"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    style={{ width: '300px' }}
                    onRemove={deleteAsset}
                    action={`${URL}/api/upload/asset`}
                    name="file"
                    listType="all"
                    headers={{
                      Authorization: `Bearer ${token}`,
                    }}
                    data={{ file_type: 'syllabus' }}
                  >
                    <Button>Upload Syllabus</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <Form.Item
                  name="uploadPreviousQNPapers"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    onRemove={deleteAsset}
                    action={`${URL}/api/upload/asset`}
                    name="file"
                    listType="all"
                    headers={{
                      Authorization: `Bearer ${token}`,
                    }}
                    data={{ file_type: 'questionPapers' }}
                  >
                    <Button>Previous Qn Papers</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Input.Group> */}
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="streamembedcode" label="Stream Embed Code">
                  <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item name="chatembedcode" label="Chat Embed Code">
                  <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item name="ytembedcode" label="Youtube Embed Code">
                  <Input.TextArea rows={5} />
                </Form.Item>

                {/* <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Select.Option value="1">Draft</Select.Option>
              <Select.Option value="2">Publish</Select.Option>
            </Select>
          </Form.Item> */}

                <Form.Item
                  name="cost"
                  label={<span>Course Cost</span>}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value || value <= 10000) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Cost should be less than or equal to 10,000 Rs')
                        );
                      },
                    }),
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" shape="round" size="large" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : null}
      </Modal>
    </>
  );
};

export default EditClass;
