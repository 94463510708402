/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Divider, Button, Card } from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import EditTopic from '../components/EditTopic';

function SingleTopicPage() {
  const { id } = useParams();
  const { MakeRequest, AuthState } = useAuth();
  const { role } = AuthState;
  const history = useHistory();
  const gettopicRequest = MakeRequest('GET', `/user/course/curriculum/topic/show/${id}`);

  const { data: { data = {}, type } = {}, mutate: mutateTopic } = useSWR(
    `/user/course/curriculum/topic/show/${id}`,
    gettopicRequest
  );

  const { topic = {} } = data;

  if (!type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (type === 'error') {
    return <AppLayout>Could not find the topic</AppLayout>;
  }

  return (
    <AppLayout>
      {role === 'admin' || role === 'instructor' ? (
        <>
          <Button
            type="primary"
            shape="round"
            htmlType="link"
            onClick={() => history.push(`/curriculum/${topic.curriculum_id}`)}
            className="switchBtnStyle"
          >
            Back To Subject
          </Button>
          &nbsp;
          <Button
            type="primary"
            shape="round"
            htmlType="link"
            onClick={() => history.push(`/course/${topic.curriculum.course_id}`)}
            className="switchBtnStyle"
          >
            Back To Course
          </Button>
          &nbsp;
          <Divider orientation="left">
            Topic:&nbsp;
            {topic.title}
          </Divider>
          <Row>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Card
                title={`${topic.title} Details`}
                actions={[<EditTopic topic={topic} mutateTopic={mutateTopic} />]}
                style={{ overflowWrap: 'break-word' }}
              >
                <h3>
                  Title: &nbsp;
                  <strong>{topic.title}</strong>
                </h3>
                <h3>
                  Description: &nbsp;
                  <strong>{topic.description}</strong>
                </h3>
                <h3>
                  Start Date: &nbsp;
                  <strong>{moment(topic.topic_date).format('Do MMM YY hh:mm A')}</strong>
                </h3>
                <h3>
                  Number Of Hours: &nbsp;
                  <strong>{topic.no_of_hours}</strong>
                </h3>

                <h3>
                  Youtube Embed Code: &nbsp;
                  <strong>{topic.video}</strong>
                </h3>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Divider orientation="left">
            Subject:&nbsp;
            {topic.title}
          </Divider>
        </>
      )}

      {role === 'student' ? <h3>{topic.title}</h3> : null}
    </AppLayout>
  );
}

export default SingleTopicPage;
