import React from 'react';
import { Row, Col } from 'antd';
import logo from '../assets/purple.png';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

function ForgotPasswordPage() {
  return (
    <Row justify="space-around">
      <Col xs={20} sm={16} md={12} lg={8} xl={5} align="center">
        <img src={logo} alt="logo" className="logo" />

        <ForgotPasswordForm />
      </Col>
    </Row>
  );
}

export default ForgotPasswordPage;
