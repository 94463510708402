import useSWR from 'swr';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AutoComplete, Col, Row } from 'antd';
import { useHistory } from 'react-router';
import { useAuth } from '../components/Auth/AuthProvider';
import AppLayout from '../components/AppLayout';
import CreateNewUserForm from '../components/CreateNewUserForm';
import AdminUserList from '../components/AdminDashboard/AdminUserList';

function ManageUsersPage() {
  const history = useHistory();
  const { MakeRequest } = useAuth();
  const UsersListRequest = MakeRequest('GET', '/users');
  const { data: user = {}, mutate: mutateAllUser } = useSWR('/users', UsersListRequest);
  const { data: { users = [] } = {} } = user;

  const [options, setOptions] = useState([{ value: '', id: null }]);

  const onFocus = () => {
    const results = users.map(x => {
      return { value: x.email, id: x.id };
    });
    setOptions(results);
  };
  const onSelect = (value, option) => {
    history.push(`/user/${option.id}`);
  };

  return (
    <AppLayout>
      <Helmet>
        <title>Manage users</title>
      </Helmet>

      <Row>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <CreateNewUserForm users={users || []} mutateAllUser={mutateAllUser} />
        </Col>

        {users.length ? (
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginInline: 'auto' }}>
            <AutoComplete
              style={{ width: 320 }}
              placeholder="Search User"
              options={options}
              onSelect={onSelect}
              onFocus={onFocus}
              filterOption={
                (inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </Col>
        ) : null}
      </Row>

      <AdminUserList users={users || []} mutateAllUser={mutateAllUser} />
    </AppLayout>
  );
}

export default ManageUsersPage;
