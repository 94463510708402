/* eslint-disable react/jsx-one-expression-per-line */
import { Card, Col, Divider, Row } from 'antd';
import moment from 'moment';
import React from 'react';

function UserProfile(props) {
  const { userDetails } = props;

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Divider orientation="left">Your Profile </Divider>

          <Card bordered>
            <h3>
              Name: <strong>{userDetails.display_name}</strong>
            </h3>
            <h3>
              Email: <strong>{userDetails.email}</strong>
            </h3>
            <h3>
              Designation: <strong>{userDetails.role}</strong>
            </h3>
            <h3>
              Phone Number: <strong>{userDetails.phone_number || `Not given`}</strong>
            </h3>
            <h3>
              Account Created On:&nbsp;
              <strong>{moment(userDetails.created_at).format('Do MMM YY hh:mm A')}</strong>
            </h3>
            <h3>
              Account Last Updated On:&nbsp;
              <strong>{moment(userDetails.updated_at).format('Do MMM YY hh:mm A')}</strong>
            </h3>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default UserProfile;
