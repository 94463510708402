import React, { useState } from 'react';
import useSWR from 'swr';
import { Helmet } from 'react-helmet-async';
import { AutoComplete, Col, Divider, Row } from 'antd';
import { useHistory } from 'react-router';
import AdminExamList from '../components/AdminDashboard/AdminExamList';
import AppLayout from '../components/AppLayout';
import CreateNewExamForm from '../components/CreateNewExamForm';
import { useAuth } from '../components/Auth/AuthProvider';

function ManageExamsPage() {
  const history = useHistory();

  const { MakeRequest } = useAuth();
  const CourseListRequest = MakeRequest('GET', '/user/courses/all');
  const { data: course = {} } = useSWR('/user/courses/all', CourseListRequest);
  const { data: { courses = [] } = {} } = course;

  const ExamListRequest = MakeRequest('GET', '/exams/all');
  const { data: exam = {}, mutate: mutateAllExam } = useSWR('/exams/all', ExamListRequest);
  const { data: { exams = [] } = {} } = exam;

  const [options, setOptions] = useState([{ value: '', id: null }]);

  const onFocus = () => {
    const results = exams.map(x => {
      return { value: x.title, id: x.id };
    });
    setOptions(results);
  };
  const onSelect = (value, option) => {
    history.push(`/exam/${option.id}`);
  };

  return (
    <AppLayout>
      <Helmet>
        <title>Manage exams</title>
      </Helmet>
      <Row>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <CreateNewExamForm
            exams={exams || []}
            courses={courses || []}
            mutateAllExam={mutateAllExam}
          />
        </Col>
        {exams.length ? (
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginInline: 'auto' }}>
            <AutoComplete
              style={{ width: 320 }}
              placeholder="Search Exam"
              options={options}
              onSelect={onSelect}
              onFocus={onFocus}
              filterOption={
                (inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </Col>
        ) : null}
      </Row>
      <Divider orientation="left">Exams</Divider>

      <AdminExamList exams={exams || []} mutateAllExam={mutateAllExam} />
    </AppLayout>
  );
}
export default ManageExamsPage;
