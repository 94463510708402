import React, { useState } from 'react';
import { Form, Input, Button, Result } from 'antd';
import notify from '../utils/Notify';
import { InitiateForgotPassword } from '../utils/AuthRequests';

const ForgotPasswordForm = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const onFinish = async formData => {
    const { email } = formData;
    setUserEmail(email);
    setIsProcessing(true);
    const { type, data } = await InitiateForgotPassword(email);
    if (type === 'error') {
      notify({ type, message: data });
      setIsProcessing(false);
    } else if (type === 'success') {
      setIsProcessing('success');
    }
  };

  if (isProcessing === 'success') {
    return (
      <Result
        status="success"
        title="Password reset link has been sent to your email"
        subTitle={`Please check ${userEmail}'s inbox to reset Password`}
      />
    );
  }

  return (
    <Form layout="vertical" name="forgotPassword" onFinish={onFinish} scrollToFirstError>
      <p>
        Enter your user account&apos;s verified email address and we will send you a password reset
        link.
      </p>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid E-mail ID',
          },
          {
            required: true,
            message: 'Please enter your E-mail ID',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button disabled={isProcessing} type="primary" shape="round" size="large" htmlType="submit">
          Send password reset email
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordForm;
