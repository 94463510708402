import Axios from 'axios';
import { getEmbedableSource } from './Utility';

Axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

export async function CourseListRequest(token) {
  const CourseListDataRequest = Axios.create();

  try {
    const CourseListDataResponse = await CourseListDataRequest({
      method: 'GET',
      url: `/user/courses`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return { type: 'success', data: CourseListDataResponse.data.courses };
  } catch (error) {
    if (error.response) {
      return { type: 'error', data: error.response.data.errors };
    }
    return { type: 'error', data: error };
  }
}

// add new class
export async function AddNewFreeClass(freeClassData, token) {
  const {
    title,
    educator,
    startdate,
    duration,
    streamembedcode,
    chatembedcode,
    ytembedcode,
    uploadSyllabus = [],
    uploadPreviousQNPapers = [],
    description,
    status,
    cost,
  } = freeClassData;

  const FreeClassDataRequest = Axios.create();

  const formattedData = {
    title,
    instructor_id: educator,
    start_date: startdate.add(330, 'minutes').format(),
    no_of_days: duration,
    event_embed_code: getEmbedableSource(streamembedcode),
    chat_embed_code: getEmbedableSource(chatembedcode),
    youtube_embed_code: getEmbedableSource(ytembedcode),
    syllabus: uploadSyllabus.length ? uploadSyllabus[0].response.url : '',
    previous_question_papers: uploadPreviousQNPapers.length
      ? uploadPreviousQNPapers[0].response.url
      : '',
    description,
    status,
    cost,
  };

  try {
    const FreeClassDataResponse = await FreeClassDataRequest({
      method: 'POST',
      url: `/course/create`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(formattedData),
    });
    return { type: 'success', data: FreeClassDataResponse.data };
  } catch (error) {
    if (error.response) {
      return { type: 'error', data: error.response.data.errors };
    }
    return { type: 'error', data: { error: ['Unknown error'] } };
  }
}

export async function ExamAnswersRequest(answers, examId, token) {
  const ExamAnswersDataRequest = Axios.create();

  const formattedData = {
    answers,
    exam_id: examId,
  };

  try {
    const ExamAnswersDataResponse = await ExamAnswersDataRequest({
      method: 'POST',
      url: `/user/exam/result/create`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(formattedData),
    });
    return { type: 'success', data: ExamAnswersDataResponse.data };
  } catch (error) {
    if (error.response) {
      return { type: 'error', data: error.response.data };
    }
    return { type: 'error', data: { error: ['Unknown error'] } };
  }
}
