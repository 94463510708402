import React from 'react';
import useSWR from 'swr';
import { useAuth } from './Auth/AuthProvider';
import UpdateProfileForm from './UpdateProfileForm';
import UserProfile from './UserProfile';

const UpdateProfile = () => {
  const { MakeRequest } = useAuth();
  const getCourseRequest = MakeRequest('GET', `/user/me`);

  const { data: { data = {} } = {}, mutate: mutateUser } = useSWR(`/user/me`, getCourseRequest);

  const user = data;

  return (
    <>
      <UpdateProfileForm userDetails={user || []} mutateUser={mutateUser} />
      <UserProfile userDetails={user || []} />
    </>
  );
};

export default UpdateProfile;
