import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EmailVerificationError from '../components/EmailVerificationError';
import EmailVerifying from '../components/EmailVerifying';
import EmailVerified from '../components/EmailVerified';
import { VerifyEmail } from '../utils/AuthRequests';

function EmailVerificationPage() {
  const { id, expires, signature } = useParams();
  const [isVerifying, setIsVerifying] = useState(true);

  useEffect(() => {
    async function AsyncVerifyEmail(_id, _expires, _signature) {
      const { type } = await VerifyEmail(_id, _expires, _signature);
      setIsVerifying(type);
    }

    AsyncVerifyEmail(id, expires, signature);
  }, []);

  if (isVerifying === true) {
    return <EmailVerifying />;
  }

  if (isVerifying === 'error') {
    return <EmailVerificationError />;
  }

  return <EmailVerified />;
}

export default EmailVerificationPage;
