/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form, InputNumber, Button, Input, Row, Col, Modal } from 'antd';
import { useAuth } from './Auth/AuthProvider';
import notify from '../utils/Notify';

const CreateNewEnrollForm = props => {
  const { course, mutateAllEnrolls, enrolls } = props;

  const { MakeRequest } = useAuth();

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const onFinish = async enrollData => {
    const AddNewExam = MakeRequest('POST', '/user/enroll/createCustomEnroll');
    const { email, transactionId, cost } = enrollData;
    const formattedData = {
      email,
      transaction_id: transactionId,
      amount: cost,
      course_id: course.id,
      // image_url: uploadImage.length ? uploadImage[0].response.url : '',
    };

    const response = await AddNewExam(formattedData);
    if (response.type === 'error') {
      // eslint-disable-next-line no-restricted-syntax
      // for (const [error] of Object.values(response.data)) {
      notify({ type: 'error', message: 'Error. User Not Found Or User already enrolled' });
      // }
      // setIsProcessing(false);
    } else if (response.type === 'success') {
      const enrollToBeAdded = {
        id: Math.random(),
        course_id: course.id,
        user_id: response.data.response.user_id,
        payment: response.data.response.payment,
        user: response.data.response.user,
      };

      mutateAllEnrolls({
        data: {
          courses: [enrollToBeAdded, ...enrolls],
        },
      });
      handleClose();

      Modal.success({
        content: `New Enroll with transaction id ${transactionId} Created`,
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        className="btnStyle"
        shape="round"
        color="primary"
        onClick={handleOpen}
      >
        Create New Enroll
      </Button>
      <Modal title="Create New Enroll" visible={visible} onCancel={handleClose} footer={null}>
        <Form layout="vertical" name="addnewenroll" onFinish={onFinish} scrollToFirstError>
          <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid E-mail ID',
                  },
                  {
                    required: true,
                    message: 'Please enter E-mail ID',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="transactionId"
                label="Transaction Id:"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Transaction Id',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="cost"
                label={<span>Course Cost</span>}
                rules={[
                  {
                    required: true,
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value <= 10000) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Cost should be less than or equal to 10,000 Rs')
                      );
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" shape="round" size="large" htmlType="submit">
                  Add Enroll
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateNewEnrollForm;
