import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Form, Button, Input, Modal } from 'antd';
import { useAuth } from './Auth/AuthProvider';

const { confirm } = Modal;

const UpdateProfileForm = props => {
  const { userDetails, mutateUser } = props;
  const { MakeRequest } = useAuth();
  const history = useHistory();

  const onFinish = async updateUserData => {
    const editUser = MakeRequest('POST', `/user/update/${userDetails.id}`);
    const { fullname, email, phone } = updateUserData;
    const formattedData = {
      display_name: fullname,
      email,
      phone_number: phone,
      role: userDetails.role,
      email_verified_at: userDetails.email_verified_at,
    };

    if (userDetails.email !== updateUserData.email) {
      confirm({
        title: 'Sure to update?',
        icon: <ExclamationCircleOutlined />,
        content: 'You changed your email-id. You will have to reverify your new email address.',
        async onOk() {
          const response = await editUser(formattedData);
          if (response.type === 'error') {
            Modal.error({
              content: `error, email-id already taken`,
            });
            return false;
          }
          const updatedUser = {
            display_name: fullname,
            email,
            phone_number: phone,
            role: userDetails.role,
            email_verified_at: userDetails.email_verified_at,
          };
          mutateUser({ ...userDetails, updatedUser });

          Modal.success({
            content: `Updated User ${fullname}`,
          });
          history.push('/logout');
          return true;
        },
        onCancel() {},
      });
    } else {
      const response = await editUser(formattedData);
      if (response.type === 'error') {
        Modal.error({
          content: `error updating user`,
        });
      } else {
        const updatedUser = {
          display_name: fullname,
          email,
          phone_number: phone,
          role: userDetails.role,
          email_verified_at: userDetails.email_verified_at,
        };
        mutateUser({ ...userDetails, updatedUser });

        Modal.success({
          content: `Updated User ${fullname}`,
        });
      }
    }
  };

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        shape="round"
        onClick={handleOpen}
        className="topicBtnStyle"
      >
        Update Your Profile
      </Button>
      <Modal title="Update Your Profile" visible={visible} onCancel={handleClose} footer={null}>
        <Form
          layout="vertical"
          name="profile"
          onFinish={onFinish}
          initialValues={{
            fullname: userDetails.display_name,
            email: userDetails.email,
            phone: userDetails.phone_number,
            // role: userDetails.role,
          }}
          scrollToFirstError
        >
          <Form.Item
            name="fullname"
            label={<span>Fullname</span>}
            rules={[
              {
                required: true,
                message: 'Please enter your fullname',
                whitespace: true,
              },
              () => ({
                validator(_, value) {
                  if (!value || (value.length >= 4 && value.length <= 25)) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Fullname should be 4 to 25 characters'));
                },
              }),
            ]}
          >
            <Input maxLength={25} />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid E-mail ID',
              },
              {
                required: true,
                message: 'Please enter your E-mail ID',
              },
            ]}
          >
            <Input name="email" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: 'Please enter your Phone Number',
              },
              () => ({
                validator(_, value) {
                  if (!value || (value.length === 10 && !Number.isNaN(value))) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Phone Number should be of 10 digits'));
                },
              }),
            ]}
          >
            <Input name="phone" />
          </Form.Item>

          <Form.Item>
            <Button
              // disabled={isProcessing}
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
            >
              UPDATE
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateProfileForm;
