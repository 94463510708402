import { Divider } from 'antd';
import React from 'react';
import useSWR from 'swr';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import StudentPremiumExamList from '../components/Student/StudentPremiumExamList';

function PremiumExamsPage() {
  const { MakeRequest } = useAuth();

  const ExamListRequest = MakeRequest('GET', '/exams/premium');
  const { data: exam = {} } = useSWR('/exams/premium', ExamListRequest);
  const { data: { exams = [] } = {} } = exam;

  return (
    <AppLayout>
      <Divider orientation="left">Premium Exams</Divider>
      <StudentPremiumExamList premiumExams={exams || []} />
    </AppLayout>
  );
}

export default PremiumExamsPage;
