import { Divider } from 'antd';
import React from 'react';
import useSWR from 'swr';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import StudentPremiumCoursesList from '../components/Student/StudentPremiumCoursesList';

function PremiumCoursesPage() {
  const { MakeRequest } = useAuth();

  const CourseListRequest = MakeRequest('GET', '/user/courses/premium');
  const { data: course = {} } = useSWR('/user/courses/premium', CourseListRequest);
  const { data: { courses = [] } = {} } = course;

  return (
    <AppLayout>
      <Divider orientation="left">Premium Courses</Divider>
      <StudentPremiumCoursesList premiumCourses={courses || []} />
    </AppLayout>
  );
}

export default PremiumCoursesPage;
