import React from 'react';
import useSWR from 'swr';
import { Divider } from 'antd';
import { useAuth } from '../../components/Auth/AuthProvider';

import AppLayout from '../../components/AppLayout';
import StudentExamList from '../../components/Student/StudentExamList';

function ExamPage() {
  const { MakeRequest } = useAuth();

  const ExamListRequest = MakeRequest('GET', '/exams/free');
  const { data: exam = {} } = useSWR('/exams/free', ExamListRequest);
  const { data: { exams = [] } = {} } = exam;

  return (
    <AppLayout>
      <Divider orientation="left">Exams</Divider>
      <StudentExamList exams={exams || []} />
    </AppLayout>
  );
}

export default ExamPage;
