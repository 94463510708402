import React from 'react';
import { Form, Button, Input, Row, Col, Modal } from 'antd';

import { useAuth } from './Auth/AuthProvider';
import notify from '../utils/Notify';
import { AddNewUser } from '../utils/AdminUserRequests';

const CreateNewUserForm = props => {
  const { users, mutateAllUser } = props;

  const { AuthState } = useAuth();
  const { token } = AuthState;

  const onFinish = async userData => {
    const response = await AddNewUser(userData, token);
    if (response.type === 'error') {
      // eslint-disable-next-line no-restricted-syntax
      for (const [error] of Object.values(response.data)) {
        notify({ type: 'error', message: error });
      }
    } else if (response.type === 'success') {
      const userToBeAdded = {
        id: Math.random(),
        created_at: new Date(),
        updated_at: new Date(),
        name: userData.fullname,
        email: userData.email,
      };

      mutateAllUser({
        data: {
          courses: [userToBeAdded, ...users],
        },
      });

      Modal.success({
        content: `New User ${userData.fullname} Created`,
      });
    }
  };

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button
        variant="contained"
        className="btnStyle"
        shape="round"
        color="primary"
        onClick={handleOpen}
      >
        Create New User
      </Button>
      <Modal title="Create New User" visible={visible} onCancel={handleCancel} footer={null}>
        <Form layout="vertical" name="addNewUser" onFinish={onFinish} scrollToFirstError>
          <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }} justify="center">
            <Col xs={24} sm={12} md={12} lg={18} xl={18}>
              <Form.Item
                name="fullname"
                label={<span>Full Name</span>}
                rules={[
                  {
                    required: true,
                    message: 'Please enter user full name',
                    whitespace: true,
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || (value.length >= 4 && value.length <= 25)) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Full name should be 4 to 25 characters'));
                    },
                  }),
                ]}
              >
                <Input maxLength={25} />
              </Form.Item>

              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid E-mail ID',
                  },
                  {
                    required: true,
                    message: 'Please enter your E-mail ID',
                  },
                ]}
              >
                <Input name="email" />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Phone Number',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || (value.length === 10 && !Number.isNaN(value))) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Phone Number should be of 10 digits'));
                    },
                  }),
                ]}
              >
                <Input name="phone" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a password',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value.length >= 8) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Password should be more than 8 characters'));
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('The two passwords that you entered do not match!')
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" shape="round" size="large" htmlType="submit">
                  Add User
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateNewUserForm;
