import React from 'react';
import { Row, Col, Spin, Space, Divider } from 'antd';

function EmailVerifying() {
  return (
    <Row className="emailVerifyingContainer" justify="space-around">
      <Col xs={20} sm={16} md={12} lg={8} xl={8} align="center">
        <Space size="large" direction="vertical">
          <Spin size="large" />
          <Divider orientation="left">Verifying your email, please wait...</Divider>
        </Space>
      </Col>
    </Row>
  );
}

export default EmailVerifying;
