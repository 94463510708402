import Axios from 'axios';
import { setLocalStorage } from './localStorage';

Axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

export async function Register(registrationData) {
  const { fullname, email, password, confirm, phone } = registrationData;

  const registrationRequest = Axios.create();

  const formattedData = {
    display_name: fullname,
    email,
    password,
    password_confirmation: confirm,
    phone_number: phone,
  };

  try {
    const registrationResponse = await registrationRequest({
      method: 'POST',
      url: `/user/register`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(formattedData),
    });
    return { type: 'success', data: registrationResponse.data };
  } catch (error) {
    if (error.response) {
      return { type: 'error', data: error.response.data.errors };
    }
    return { type: 'error', data: { error: ['Unknown error'] } };
  }
}

export async function VerifyEmail(id, expires, signature) {
  const emailVerificationRequest = Axios.create();

  try {
    await emailVerificationRequest({
      method: 'GET',
      url: `/email/verify/${id}?expires=${expires}&signature=${signature}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return { type: 'success' };
  } catch (error) {
    return { type: 'error' };
  }
}

export async function Login(loginCredentials) {
  const { email, password } = loginCredentials;

  const loginRequest = Axios.create();

  const formattedData = {
    email,
    password,
  };

  try {
    const loginResponse = await loginRequest({
      method: 'POST',
      url: `/user/login`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(formattedData),
    });

    const { data } = loginResponse;

    // eslint-disable-next-line camelcase
    const { user, access_token } = data;

    const formattedAuthDetails = {
      displayName: user.display_name,
      isLoggedIn: true,
      token: access_token,
      role: user.role,
      email: user.email,
    };

    setLocalStorage('auth_details', formattedAuthDetails);

    return { type: 'success', data: formattedAuthDetails };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { type: 'error', data: error.response.data.message };
      }

      if (error.response.data.message === 'EMAIL_NOT_VERIFIED') {
        return { type: 'error', data: 'Email is not verified!' };
      }
    }

    return { type: 'error', data: 'Unknown error' };
  }
}

export async function InitiateForgotPassword(email) {
  const forgotPasswordRequest = Axios.create();

  const formattedData = {
    email,
  };

  try {
    const forgotPasswordResponse = await forgotPasswordRequest({
      method: 'POST',
      url: `/password/email`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(formattedData),
    });
    return { type: 'success', data: forgotPasswordResponse.data };
  } catch (error) {
    if (error.response) {
      return { type: 'error', data: error.response.data.error };
    }

    return { type: 'error', data: { error: ['Unknown error'] } };
  }
}

export async function ResetPassword(resetPasswordData, email, token) {
  const resetPasswordRequest = Axios.create();

  const { password, confirm } = resetPasswordData;

  const formattedData = {
    email,
    password,
    password_confirmation: confirm,
    token,
  };

  try {
    const resetPasswordResponse = await resetPasswordRequest({
      method: 'POST',
      url: `/password/reset`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(formattedData),
    });
    return { type: 'success', data: resetPasswordResponse.data };
  } catch (error) {
    if (error.response) {
      return { type: 'error', data: error.response.data.error };
    }

    return { type: 'error', data: { error: ['Unknown error'] } };
  }
}

export async function ResendEmail(resendData) {
  const resendRequest = Axios.create();

  try {
    const resendResponse = await resendRequest({
      method: 'GET',
      url: `/email/resend/${resendData.email}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return { type: 'success', data: resendResponse.data };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { type: 'error', data: error.response.data.message };
      }

      if (error.response.data.message === 'ALREADY_VERIFIED') {
        return { type: 'error', data: 'Email is already verified!' };
      }
    }

    return { type: 'error', data: { error: ['Unknown error'] } };
  }
}

export async function GoogleAppLogin(loginCredentials) {
  const { loginEmail, name } = loginCredentials;

  const loginRequest = Axios.create();

  const formattedData = {
    display_name: name,
    email: loginEmail,
  };

  try {
    const loginResponse = await loginRequest({
      method: 'POST',
      url: `/user/google/login`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(formattedData),
    });

    const { data } = loginResponse;

    // eslint-disable-next-line camelcase
    const { user, access_token } = data;

    const formattedAuthDetails = {
      displayName: user.display_name,
      isLoggedIn: true,
      token: access_token,
      role: user.role,
    };

    setLocalStorage('auth_details', formattedAuthDetails);

    return { type: 'success', data: formattedAuthDetails };
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { type: 'error', data: error.response.data.message };
      }

      if (error.response.data.message === 'EMAIL_NOT_VERIFIED') {
        return { type: 'error', data: 'Email is not verified!' };
      }
    }

    return { type: 'error', data: { error: ['Unknown error'] } };
  }
}
