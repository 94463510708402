import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Result, Divider } from 'antd';
import notify from '../utils/Notify';
import { ResendEmail } from '../utils/AuthRequests';

const ResendVerificationForm = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const onFinish = async resendData => {
    setIsProcessing(true);

    const response = await ResendEmail(resendData);

    if (response.type === 'error') {
      notify({ type: 'error', message: response.data });
      setIsProcessing(false);
    } else if (response.type === 'success') {
      setIsProcessing('success');
    }
  };

  if (isProcessing === 'success') {
    return (
      <Result
        status="success"
        title="New Verification Mail has been sent to your Email Id"
        extra={[
          <Link key="Login" className="ant-btn ant-btn-primary" to="/login">
            Login
          </Link>,
        ]}
      />
    );
  }

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Divider orientation="left">Resend Verification</Divider>

        <Form.Item
          name="email"
          label="Email:"
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid E-mail ID',
            },
            {
              required: true,
              message: 'Please enter your E-mail ID',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={isProcessing}
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
          >
            Resend
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ResendVerificationForm;
