import React from 'react';
import { Col, Divider, Image, Row } from 'antd';
import '../../App.css';
import abdulKalam from '../../assets/Abdul_kalam.jpeg';

function Quote() {
  return (
    <>
      <Row>
        <Col className="dashboard-col-style" xs={24} sm={24} md={16} lg={16} xl={16}>
          <h3 style={{ color: '#a544a5' }}>
            “Dream, Dream, Dream. Dreams transform into thoughts and thoughts result in action.”
          </h3>
          <Divider orientation="left" plain>
            -&nbsp;A. P. J. Abdul Kalam
          </Divider>
        </Col>
        <Col className="dashboard-col-style" xs={24} sm={24} md={16} lg={8} xl={8}>
          <Image width={200} src={abdulKalam} />
        </Col>
      </Row>
    </>
  );
}

export default Quote;
