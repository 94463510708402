/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Result,
  Steps,
  Progress,
  Divider,
  Table,
  Card,
  Image,
  Modal,
} from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { SmileTwoTone } from '@ant-design/icons';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import logo from '../assets/logo-388x153.png';
import AdminExam from '../components/AdminExam';
import SingleQuestion from '../components/SingleQuestion';
import QuestionAndAnswersList from '../components/QuestionAndAnswersList';

// const examThumbnail = 'https://via.placeholder.com/460x320.png?text=Exam+Thumbnail';
const examThumbnail =
  'https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80';
const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;

const { Step } = Steps;

const steps = [
  {
    title: 'exam Details',
  },
  {
    title: 'Enroll',
  },
  {
    title: 'Success',
  },
];

function SingleExamPage() {
  const { id } = useParams();

  const { AuthState, MakeRequest } = useAuth();
  const { role } = AuthState;
  const getExamRequest = MakeRequest('GET', `/exam/${id}`);

  const { data: { data = {}, type } = {}, mutate: mutateExam } = useSWR(
    `/exam/${id}`,
    getExamRequest
  );

  const { exam = {} } = data;
  const [visible, setVisible] = React.useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [boughtExam, setBoughtExam] = useState(null);

  const [current, setCurrent] = React.useState(0);
  // const [questionsPage, setQuestionsPage] = useState(1);

  const product = {
    amount: exam.cost,
    exam_id: exam.id,
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  if (!type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (type === 'error') {
    return <AppLayout>Could not find the exam</AppLayout>;
  }

  // const ConditionalMenu = () => {
  //   if (role === 'admin') {
  //     return <AdminSingleExam exam={exam} />;
  //   }
  //   if (role === 'instructor') {
  //     return <p>instructor</p>;
  //   }

  //   await enrollInExam({
  //     exam_id: exam.id,
  //   });
  //   setIsProcessing('success');
  //   next();
  // };

  const columns = [
    {
      title: 'Rank',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Total Marks',
      dataIndex: 'total_marks',
      key: 'total_marks',
    },

    {
      title: 'Name',
      dataIndex: 'user',
      key: 'student',
      render: user => {
        return user.display_name;
      },
    },
  ];

  const createOrderId = async () => {
    const createOrderIdRequest = MakeRequest('POST', `/payment/create/orderId`);
    const res = await createOrderIdRequest({
      price: exam.cost,
      currency: 'INR',
    });
    return res.data.orderId;
  };

  const razorPayPoUp = orderId => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: exam.cost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: 'Officers Adda',
      description: 'Transaction',
      image: { logo },
      order_id: orderId,
      handler: async response => {
        if (!response.razorpay_signature) {
          showModal();
        } else {
          setIsProcessing(false);

          const res = {
            transaction_id: {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              order_id: orderId,
            },
            amount: product.amount,
            exam_id: exam.id,
            email: 'test@test.com',
          };
          const premiumexamEnrollRequest = MakeRequest('POST', `/user/premium/exam/enroll/create`);
          const enrollResponse = await premiumexamEnrollRequest(res);
          setBoughtExam(enrollResponse.data.response);
          setIsProcessing('success');
          next();
        }
      },
      prefill: {
        name: 'DCentMass',
        email: 'test@test.com',
        contact: '9999999999',
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };
  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    await razorPayPoUp(orderId);
  };

  if (visible) {
    return (
      <>
        <Modal title="Payment Error" visible={visible} onCancel={handleCancel} footer={null}>
          <Result
            status="error"
            title="Transaction Error"
            subTitle="Payment processing by partner bank"
          />
        </Modal>
      </>
    );
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Row gutter={[16, 8]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                {/* <div
                  style={{
                    // width: 390,
                    // height: 215.6,
                    backgroundImage: `url('${exam.image_url || examThumbnail}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                /> */}
                <Image src={exam.image_url || examThumbnail} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <h3>
                  Exam:&nbsp;
                  {exam.title}
                </h3>
                <h3>Starts On: {moment(exam.start_date).format('Do MMM YY hh:mm A')}</h3>
                <h3>Ends On: {moment(exam.end_date).format('Do MMM YY hh:mm A')}</h3>{' '}
                {exam.cost > 0 ? (
                  <>
                    <h3>Cost:&nbsp; ₹{exam.cost}</h3>

                    <h3>Total Amount:&nbsp; ₹{exam.cost}</h3>
                  </>
                ) : null}
                <br />
                <strong> Duration:</strong>
                &nbsp;
                {exam.exam_duration}
                &nbsp; minutes
                <br />
                <strong> Negative mark:</strong>
                &nbsp;
                {exam.negative_mark}
                <br />
                {/* <strong> Description:</strong>
                &nbsp;
                {exam.description} */}
              </Col>
            </Row>
          </>
        );
      case 1:
        return (
          <>
            <Row gutter={[16, 8]} style={{ margin: '0px 0px 0px' }}>
              <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{ padding: '4px 8px' }}>
                {isProcessing === 'processing' ? (
                  <>
                    <Divider orientation="left">Please Wait... Processing your purchase</Divider>
                    <Progress percent={70} status="active" />
                  </>
                ) : (
                  <>
                    <Divider orientation="left">Confirm Details</Divider>
                    <h3>
                      Exam:&nbsp;
                      {exam.title}
                    </h3>
                    <h3>Starts On: {moment(exam.start_date).format('Do MMM YY hh:mm A')}</h3>
                    <h3>Ends On: {moment(exam.end_date).format('Do MMM YY hh:mm A')}</h3>{' '}
                    {exam.cost > 0 ? (
                      <>
                        <h3>Cost:&nbsp; ₹{exam.cost}</h3>

                        <h3>Total Amount:&nbsp; ₹{exam.cost}</h3>
                      </>
                    ) : null}
                    <br />
                    <Button
                      type="primary"
                      shape="round"
                      id="rzp-button1"
                      onClick={exam.cost ? handleRazorPayPayment : null}
                    >
                      Click to Enroll
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </>
        );

      case 2:
        return (
          <>
            {exam.cost > 0 ? (
              <Result
                status="success"
                title={`Enrolled to premium exam ${exam.title}`}
                subTitle={`Your transaction id is ${boughtExam.payment.transaction_id}`}
              />
            ) : (
              <Result status="success" title={`Enrolled to exam ${exam.title}`} />
            )}
          </>
        );

      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <AppLayout>
        {role === 'admin' || role === 'instructor' ? (
          <AdminExam exam={exam} mutateExam={mutateExam} />
        ) : typeof exam.examResult === 'object' &&
          exam.examResult !== null &&
          exam.examResult.top_ten_ranks ? (
          // eslint-disable-next-line react/jsx-indent
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Divider orientation="left">Exam: {exam.title}</Divider>
              <Card title="Your Result">
                <span>
                  {moment().isSameOrAfter(exam.end_date) ? null : (
                    <p> Your rank may change as others are still writing.</p>
                  )}
                </span>
                <h3>
                  Rank:&nbsp;
                  {exam.examResult.userRank}
                </h3>
                <h3>
                  Total Marks:&nbsp;
                  {exam.examResult.result.total_marks}
                </h3>
                {exam.examResult.top_ten_ranks.length ? (
                  <>
                    <Divider orientation="left">Top Ten Ranks</Divider>

                    <Table
                      columns={columns}
                      rowKey="id"
                      dataSource={exam.examResult.top_ten_ranks}
                      loading={exam.examResult.top_ten_ranks.length}
                      scroll={{ x: 360 }}
                      pagination={false}
                    />
                  </>
                ) : null}
              </Card>
            </Col>
            {exam.examResult.result.answers ? (
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <QuestionAndAnswersList
                  questions={exam.questions}
                  answers={exam.examResult.result.answers}
                />
              </Col>
            ) : null}
          </Row>
        ) : exam.isEnrolled ? (
          <>
            <Divider orientation="left">Exam: {exam.title}</Divider>

            {exam.questions.length && moment().isSameOrBefore(exam.end_date) ? (
              <>
                <SingleQuestion exam={exam} />
              </>
            ) : (
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                  <Card
                    title={
                      moment().isSameOrAfter(exam.end_date)
                        ? `Too late! This Exam has ended.`
                        : `You are Enrolled to this exam`
                    }
                  >
                    <h3>Starts On: {moment(exam.start_date).format('Do MMM YY hh:mm A')}</h3>
                    <h3>Ends On: {moment(exam.end_date).format('Do MMM YY hh:mm A')}</h3>
                    <h3>
                      Duration: {moment(exam.end_date).diff(moment(exam.start_date), 'minutes')}
                      &nbsp; minutes
                    </h3>
                    <h3>
                      Negative Mark: &nbsp;
                      {exam.negative_mark}
                    </h3>
                    <p>
                      Do not miss on timings. Good Luck&nbsp; <SmileTwoTone />
                    </p>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        ) : (
          <>
            <Divider orientation="left">Exam: {exam.title}</Divider>

            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">
              <Card>{getStepContent(current)}</Card>
            </div>
            <div className="steps-action">
              {current === 1 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Previous
                </Button>
              )}
              {current === 0 && (
                <Button type="primary" onClick={() => next()}>
                  Next
                </Button>
              )}
            </div>
          </>
        )}
      </AppLayout>
    </>
  );
}

export default SingleExamPage;
