import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  MenuOutlined,
  AppstoreFilled,
  DatabaseFilled,
  BookFilled,
  LockFilled,
  UsergroupAddOutlined,
  SettingFilled,
  TagFilled,
  WalletFilled,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import UserAvatarName from './UserAvatarName';

import '../App.css';
import { useAuth } from './Auth/AuthProvider';

const { Sider } = Layout;

function Sidebar() {
  const { AuthState } = useAuth();
  const history = useHistory();
  const { location } = history;
  const { role, displayName } = AuthState;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const logoutView = (
    <Menu.Item className="subMenuItem" key="/logout">
      <LockFilled />
      Logout
    </Menu.Item>
  );

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onMenuSelect = selectedMenu => {
    const { key } = selectedMenu;
    history.push(key);
  };

  const ConditionalMenu = () => {
    if (role === 'admin') {
      return (
        <Menu
          onSelect={onMenuSelect}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['/']}
          selectedKeys={[location.pathname]}
          className="menu-padding"
        >
          <UserAvatarName displayName={displayName} />
          <Menu.Item className="subMenuItem" key="/">
            <AppstoreFilled />
            Dashboard
          </Menu.Item>
          <Menu.Item className="subMenuItem" key="/manage-users">
            <UsergroupAddOutlined />
            Manage Users
          </Menu.Item>
          <Menu.Item className="subMenuItem" key="/manage-classes">
            <DatabaseFilled />
            Manage Classes
          </Menu.Item>
          <Menu.Item className="subMenuItem" key="/manage-exams">
            <BookFilled />
            Manage Exams
          </Menu.Item>
          <Menu.Item className="subMenuItem" key="/settings">
            <SettingFilled />
            Settings
          </Menu.Item>
          {logoutView}
        </Menu>
      );
    }

    if (role === 'instructor') {
      return (
        <Menu
          onSelect={onMenuSelect}
          defaultSelectedKeys={['/']}
          selectedKeys={[location.pathname]}
          theme="dark"
          mode="inline"
          className="menu-padding"
        >
          <UserAvatarName displayName={displayName} />
          <Menu.Item className="subMenuItem" key="/">
            <AppstoreFilled />
            Dashboard
          </Menu.Item>
          <Menu.Item className="subMenuItem" key="/manage-classes">
            <DatabaseFilled />
            Manage Classes
          </Menu.Item>
          <Menu.Item className="subMenuItem" key="/manage-exams">
            <BookFilled />
            Manage Exams
          </Menu.Item>
          <Menu.Item className="subMenuItem" key="/settings">
            <SettingFilled />
            Settings
          </Menu.Item>
          {logoutView}
        </Menu>
      );
    }

    return (
      <Menu
        onSelect={onMenuSelect}
        defaultSelectedKeys={['/']}
        selectedKeys={[location.pathname]}
        theme="dark"
        mode="inline"
        className="menu-padding"
      >
        <UserAvatarName displayName={displayName} />
        <Menu.Item className="subMenuItem" key="/">
          <AppstoreFilled />
          Dashboard
        </Menu.Item>
        <Menu.Item className="subMenuItem" key="/free-course">
          <DatabaseFilled />
          Free Courses
        </Menu.Item>
        <Menu.Item className="subMenuItem" key="/premium-course">
          <TagFilled />
          Premium Courses
        </Menu.Item>
        <Menu.Item className="subMenuItem" key="/exam">
          <BookFilled />
          Free Exams
        </Menu.Item>
        <Menu.Item className="subMenuItem" key="/premium-exam">
          <WalletFilled />
          Premium Exams
        </Menu.Item>
        {/* <Menu.Item className="subMenuItem" key="ca">
          Current Affairs
        </Menu.Item> */}
        <Menu.Item className="subMenuItem" key="/settings">
          <SettingFilled />
          Settings
        </Menu.Item>
        {logoutView}
      </Menu>
    );
  };

  return (
    <Sider
      style={{ position: 'fixed' }}
      trigger={<MenuOutlined />}
      collapsedWidth="0"
      breakpoint="xs"
      collapsed={isCollapsed}
      onCollapse={handleCollapse}
    >
      <ConditionalMenu />
    </Sider>
  );
}

export default Sidebar;
