import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { errorHandler } from '../../utils/HandleErrors';

import { useAuth } from './AuthProvider';

const useRequest = (
  method = 'GET',
  url
  // more = {}
) => {
  const history = useHistory();
  const { MakeRequest } = useAuth();
  const { data } = useQuery(url, MakeRequest(method, url), {
    onError: ee => errorHandler(ee, history),
  });

  return data || {};
};

// eslint-disable-next-line import/prefer-default-export
export { useRequest };
