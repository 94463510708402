/* eslint-disable no-nested-ternary */
/* eslint-disable no-loop-func */
import React, { useState } from 'react';
import { Radio, Card, Statistic, Button } from 'antd';
import moment from 'moment';
import { useAuth } from './Auth/AuthProvider';
import { ExamAnswersRequest } from '../utils/CourseRequests';

const { Countdown } = Statistic;

const CustomCountdown = React.memo(({ examDuration, onSubmit }) => (
  <Countdown title="Countdown" value={Date.now() + 1000 * 60 * examDuration} onFinish={onSubmit} />
));

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};
function SingleQuestion(props) {
  const { exam } = props;
  const { AuthState } = useAuth();
  const [answers, setAnswers] = useState([]);
  const { token } = AuthState;
  const [i, setI] = React.useState(0);
  const [examResult, setExamResult] = useState({ isSubmitted: false });

  const onChange = (e, questionId) => {
    if (answers && answers.find(element => element.question_id === questionId)) {
      const updatedAnswers = answers.map(singleAnswer => {
        if (singleAnswer.question_id === questionId) {
          return {
            ...singleAnswer,
            given_answer: e.target.value,
          };
        }
        return singleAnswer;
      });
      setAnswers(updatedAnswers);
    } else {
      setAnswers([...answers, { given_answer: e.target.value, question_id: questionId }]);
    }
  };

  const checkAnswers = singleQuestion => {
    const found = answers.some(singleAnswer => singleAnswer.question_id === singleQuestion.id);
    if (!found) answers.push({ question_id: singleQuestion.id, given_answer: 'not given' });
    return true;
  };

  const submitAnswers = async () => {
    await exam.questions.every(checkAnswers);
    const result = await ExamAnswersRequest(answers, exam.id, token);
    setExamResult({ ...result.data, isSubmitted: true });
  };

  const onNext = () => {
    setI(i + 1);
  };

  const onPrevious = () => {
    setI(i - 1);
  };

  const onSubmit = () => {
    setExamResult({ isSubmitted: true });
    setI(exam.questions.length || 99999);
    submitAnswers();
  };

  const memoOnSubmit = React.useCallback(() => {
    onSubmit();
  }, []);

  const ActionButtonsArray = () => {
    const actionButtons = [];
    if (i === exam.questions.length - 1) {
      actionButtons.push(<Button onClick={onPrevious}>Previous</Button>);
      actionButtons.push(
        <Button type="primary" onClick={onSubmit}>
          Submit
        </Button>
      );

      return actionButtons;
    }

    if (i !== 0) {
      actionButtons.push(<Button onClick={onPrevious}>Previous</Button>);
    }

    actionButtons.push(<Button onClick={onNext}>Next</Button>);
    return actionButtons;
  };

  const currentAnswer = examId => {
    const givenAnswer = answers.find(singleAnswer => singleAnswer.question_id === examId);
    return givenAnswer ? givenAnswer.given_answer : '';
  };

  return (
    <>
      <Card>
        {examResult.isSubmitted ? null : (
          <CustomCountdown examDuration={exam.exam_duration} onSubmit={memoOnSubmit} />
        )}
      </Card>
      {exam.questions[i] && !examResult.isSubmitted ? (
        <Card title={`${i + 1}. ${exam.questions[i].question}`} actions={ActionButtonsArray()}>
          <Radio.Group
            onChange={e => onChange(e, exam.questions[i].id)}
            buttonStyle="solid"
            value={currentAnswer(exam.questions[i].id)}
          >
            {exam.questions[i].options.map(option => {
              return (
                <>
                  <Radio style={radioStyle} value={option.optionText}>
                    {option.optionText}
                  </Radio>
                </>
              );
            })}
          </Radio.Group>
        </Card>
      ) : (
        <Card title="Thanks for Writing the Exam.">
          {moment().isSameOrAfter(exam.end_date) ? null : (
            <p>
              Comeback&nbsp;
              {moment().to(exam.end_date)}
              &nbsp;to view your rank
            </p>
          )}
          {'id' in examResult ? (
            <>
              <h3>
                Total Marks:&nbsp;
                {examResult.total_marks.toFixed(2)}
              </h3>
            </>
          ) : null}
        </Card>
      )}
    </>
  );
}

export default SingleQuestion;
