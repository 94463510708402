import React from 'react';
import { Layout } from 'antd';
import logo from '../assets/logo-388x153.png';

const { Header } = Layout;

const Head = () => {
  return (
    <Header className="header site-layout-background">
      <div className="nav-logo-container">
        <img src={logo} alt="logo" className="nav-logo" />
      </div>
    </Header>
  );
};

export default Head;
