/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { DeleteFilled } from '@ant-design/icons';
import { Button, Card, Divider, Modal, Popconfirm, Tooltip, List } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../components/Auth/AuthProvider';

function updateCourseCurriculumTopics(topics, topicId, mutateFunction) {
  const updatedCurriculum = topics.map(singleTopic => {
    return singleTopic.id !== topicId;
  });

  mutateFunction(updatedCurriculum);
}

function TopicsList(props) {
  const { topics, role, mutateCurriculum } = props;
  const { MakeRequest } = useAuth();
  const history = useHistory();

  const deleteTopic = async (id, title) => {
    const deleteTopicRequest = MakeRequest('DELETE', `/delete/topic/${id}`);
    await deleteTopicRequest();
    updateCourseCurriculumTopics(topics, id, mutateCurriculum);
    Modal.success({
      content: `Topic ${title} Deleted`,
    });
  };
  return (
    <>
      {topics.length ? (
        <>
          <Divider orientation="left">Topics</Divider>
        </>
      ) : (
        <>
          <Divider orientation="left">No Topics found</Divider>
        </>
      )}

      {topics.length ? (
        <Card bordered={false}>
          <List
            itemLayout="horizontal"
            dataSource={topics}
            renderItem={(topic, index) => (
              <List.Item
                extra={
                  role === 'admin' || role === 'instructor' ? (
                    <>
                      <Button
                        shape="round"
                        className="topicBtnStyle"
                        onClick={() => history.push(`/topic/${topic.id}`)}
                      >
                        View
                      </Button>
                      &nbsp;
                      <Tooltip title={`Delete Topic ${topic.title}`} placement="right">
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => deleteTopic(topic.id, topic.title)}
                        >
                          <Button shape="round" className="deleteBtnStyle">
                            <DeleteFilled />
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    </>
                  ) : null
                }
              >
                <List.Item.Meta title={`${index + 1}. ${topic.title}`} />
              </List.Item>
            )}
          />
        </Card>
      ) : (
        <h3 style={{ color: '#a544a5' }}>No Topics found</h3>
      )}
    </>
  );
}

export default TopicsList;
