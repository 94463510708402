import React from 'react';
import useSWR from 'swr';
import { Divider } from 'antd';
import { useAuth } from '../../components/Auth/AuthProvider';

import AppLayout from '../../components/AppLayout';
import StudentCourseList from '../../components/Student/StudentCourseList';

function FreeCoursePage() {
  const { MakeRequest } = useAuth();

  const CourseListRequest = MakeRequest('GET', '/user/courses/free');
  const { data: course = {} } = useSWR('/user/courses/free', CourseListRequest);
  const { data: { previousCourses = [], upcomingCourses = [] } = {} } = course;

  return (
    <AppLayout>
      <Divider orientation="left">Upcoming Courses</Divider>
      <StudentCourseList courses={upcomingCourses || []} />
      <Divider orientation="left">Previous Courses</Divider>
      <StudentCourseList courses={previousCourses || []} />
    </AppLayout>
  );
}

export default FreeCoursePage;
