import React from 'react';
import '../../App.css';
import { Row, Col, Divider } from 'antd';
import Quote from '../dashboard/Quote';
import Latest from '../dashboard/Latest';
import StudentPremiumCoursesList from '../Student/StudentPremiumCoursesList';
import StudentPremiumExamList from '../Student/StudentPremiumExamList';
import { useRequest } from '../Auth/useRequest';

function StudentDashboard() {
  const course = useRequest('GET', '/user/enrolls');
  const { data: { courses = [] } = {} } = course;

  const exam = useRequest('GET', '/user/exam/enrolls');
  const { data: { exams = [] } = {} } = exam;

  return (
    <>
      <Quote />
      <Row>
        <Col className="dashboard-col-style" xs={24} sm={24} md={20} lg={20} xl={20}>
          {courses.length ? (
            <>
              <Divider orientation="left">Your Enrolled Premium Courses</Divider>
              <StudentPremiumCoursesList premiumCourses={courses} />
            </>
          ) : null}

          {exams.length ? (
            <>
              <Divider orientation="left">Your Enrolled Premium Exams</Divider>
              <StudentPremiumExamList premiumExams={exams} />
            </>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col className="dashboard-col-style" xs={24} sm={24} md={16} lg={16} xl={16}>
          <Divider orientation="left">Video guide:</Divider>
          <iframe
            title="test"
            width="90%"
            height="315"
            src="https://www.youtube.com/embed/MZj_AKjwXa0"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Col>

        <Col className="dashboard-col-style" xs={24} sm={24} md={8} lg={8} xl={8}>
          <Latest />
        </Col>
      </Row>
    </>
  );
}

export default StudentDashboard;
