/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Table } from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';

function ExamEnrollspage() {
  const { id } = useParams();
  const { MakeRequest, AuthState } = useAuth();
  const { role } = AuthState;

  const ExamListRequest = MakeRequest('GET', `/exam/enrolls/${id}`);
  const { data: res = {} } = useSWR(`/exam/enrolls/${id}`, ExamListRequest);
  const { data: { exam = {} } = {} } = res;

  if (!res.type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (res.type === 'error') {
    return <AppLayout>Could not find the course</AppLayout>;
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'user',
      render: user => {
        return user.display_name;
      },
    },

    {
      title: 'Email',
      dataIndex: 'user',
      render: user => {
        return user.email;
      },
    },

    {
      title: 'Transaction Id',
      dataIndex: 'payment',
      render: payment => {
        return payment.transaction_id;
      },
    },
    {
      title: 'Paid Amount(Rs)',
      dataIndex: 'payment',
      render: payment => {
        return payment.amount;
      },
    },

    {
      title: 'Enrolled Date',
      dataIndex: 'created_at',
      key: 'Edate',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },
  ];

  return (
    <AppLayout>
      {role === 'admin' || role === 'instructor' ? (
        <>
          <Divider orientation="left">Exam: {exam.title}</Divider>
          <Divider orientation="left">Enrolled Students</Divider>
          <Table columns={columns} rowKey="id" dataSource={exam.exam_enrolls} scroll={{ x: 360 }} />
        </>
      ) : (
        <Divider orientation="left">Not Authorized</Divider>
      )}
    </AppLayout>
  );
}

export default ExamEnrollspage;
