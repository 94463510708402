import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Card, Divider, List, Tooltip } from 'antd';
import React, { useState } from 'react';

function QuestionAndAnswersList(props) {
  const { questions, answers } = props;
  const [questionsPage, setQuestionsPage] = useState(1);

  const renderQuestionAnswers = () => {
    const list = questions.map(singleQuestion => {
      const result = answers.find(obj => {
        return obj.question_id === singleQuestion.id;
      });
      return { ...singleQuestion, givenAnswer: result.given_answer };
    });
    return (
      <>
        <Divider orientation="left">Questions and Given Answers</Divider>
        <Card>
          <div>
            <List
              itemLayout="vertical"
              dataSource={list}
              pagination={{
                onChange(current) {
                  setQuestionsPage(current);
                },
                pageSize: 10,
              }}
              renderItem={(question, index) => (
                <List.Item>
                  <List.Item.Meta
                    title={`${(questionsPage - 1) * 10 + index + 1}.${question.question}`}
                  />
                  {question.givenAnswer === 'not given' ? (
                    <span>
                      <strong> Answer Not Given </strong>
                    </span>
                  ) : null}
                  {question.options.length ? (
                    question.options.map(option => {
                      return (
                        <>
                          <Card>
                            {option.isCorrect ? (
                              <>
                                <p>
                                  <Tooltip
                                    title={`Correct Answer ${option.optionText}`}
                                    placement="right"
                                  >
                                    {option.optionText}
                                    &nbsp;
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                    {question.givenAnswer === option.optionText ? (
                                      <span>
                                        <strong> Given Answer </strong>
                                      </span>
                                    ) : null}
                                  </Tooltip>
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  <Tooltip
                                    title={`Wrong Answer ${option.optionText}`}
                                    placement="right"
                                  >
                                    {option.optionText}
                                    &nbsp;
                                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                                  </Tooltip>

                                  {question.givenAnswer === option.optionText ? (
                                    <span>
                                      <strong> Given Answer </strong>
                                    </span>
                                  ) : null}
                                </p>
                              </>
                            )}
                          </Card>
                        </>
                      );
                    })
                  ) : (
                    <Divider orientation="left">
                      No Options found in &nbsp;
                      {question.question}
                    </Divider>
                  )}
                </List.Item>
              )}
            />
          </div>
        </Card>
      </>
    );
  };

  return <>{renderQuestionAnswers()}</>;
}

export default QuestionAndAnswersList;
