import React from 'react';
import {
  Form,
  Select,
  InputNumber,
  DatePicker,
  Button,
  Upload,
  Input,
  Row,
  Col,
  Modal,
} from 'antd';
import moment from 'moment';
import useSWR from 'swr';
import { useAuth } from './Auth/AuthProvider';
import notify from '../utils/Notify';

const URL = process.env.REACT_APP_BACKEND_API_PATH;

function EditExam(props) {
  const { exam, mutateExam } = props;

  const { MakeRequest, AuthState } = useAuth();
  const { token } = AuthState;

  const CourseListRequest = MakeRequest('GET', '/user/courses/all');
  const { data: course = {} } = useSWR('/user/courses/all', CourseListRequest);
  const { data: { courses = [] } = {} } = course;

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const [updateCourse, onUpdateCourse] = React.useState(null);

  const onValuesChange = e => {
    if (e.examCourse) {
      onUpdateCourse(e.examCourse);
    }
  };

  const onFinish = async examData => {
    const UpdateExam = MakeRequest('POST', `/exam/update/${exam.id}`);
    const {
      title,
      startDate,
      endDate,
      examDuration,
      uploadImage = [],
      positiveMark,
      negativeMark,
      cost,
    } = examData;
    const formattedData = {
      title,
      start_date: startDate.add(330, 'minutes').format(),
      end_date: endDate.add(330, 'minutes').format(),
      exam_duration: examDuration,
      course_id: updateCourse || exam.course_id,
      image_url: uploadImage.length ? uploadImage[0].response.url : '',
      positive_mark: positiveMark,
      negative_mark: negativeMark,
      cost,
      status: exam.status,
      questions: exam.questions,
    };

    const response = await UpdateExam(formattedData);
    if (response.type === 'error') {
      notify({ type: 'error', message: 'error. check if the title is unique' });
    } else if (response.type === 'success') {
      const updatedExam = {
        title: examData.title,
        start_date: examData.startDate.add(330, 'minutes').format(),
        end_date: examData.endDate.add(330, 'minutes').format(),
        exam_duration: examData.examDuration,
        course_id: updateCourse || exam.course_id,
        image_url: examData.uploadImage,
        positive_mark: examData.positiveMark,
        negative_mark: examData.negativeMark,
        status: exam.status,
      };

      mutateExam({ ...exam, updatedExam });

      Modal.success({
        content: `Updated Exam ${exam.title}`,
      });
    }
  };

  const deleteAsset = async fileObject => {
    const { response } = fileObject;
    const deleteAssetRequest = MakeRequest('DELETE', '/delete/asset');
    await deleteAssetRequest({
      url: response.url,
    });
  };

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button
        variant="contained"
        className="btnStyle"
        shape="round"
        color="primary"
        onClick={handleOpen}
      >
        Edit Exam
      </Button>
      <Modal title="Edit Exam" visible={visible} onCancel={handleClose} footer={null}>
        <Form
          layout="vertical"
          name="addNewExam"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            title: exam.title,
            description: exam.description,
            cost: exam.cost,
            examCourse: exam.course ? exam.course.title : null,
            startDate: moment(exam.start_date),
            endDate: moment(exam.end_date),
            examDuration: exam.exam_duration,
            positiveMark: exam.positive_mark,
            negativeMark: exam.negative_mark,
          }}
          scrollToFirstError
        >
          <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }} justify="center">
            <Col xs={24} sm={12} md={12} lg={18} xl={18}>
              <Form.Item
                name="title"
                label={<span>Title</span>}
                rules={[
                  {
                    required: true,
                    message: 'Please enter exam title',
                    whitespace: true,
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value.length >= 4) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Title should be more than 2 characters'));
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="startDate"
                label="Start Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>

              <Form.Item
                name="endDate"
                label="End Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker showTime />
              </Form.Item>

              <Form.Item name="examDuration" label="Exam duration(min)">
                <InputNumber min={10} />
              </Form.Item>

              <Form.Item name="examCourse" label="Course">
                <Select>
                  {courses.map(singleCourse => (
                    <Select.Option key={singleCourse.id} value={singleCourse.id}>
                      {singleCourse.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="uploadImage" valuePropName="fileList" getValueFromEvent={normFile}>
                <Upload
                  onRemove={deleteAsset}
                  action={`${URL}/api/upload/asset`}
                  name="file"
                  listType="all"
                  headers={{
                    Authorization: `Bearer ${token}`,
                  }}
                  data={{ file_type: 'exam' }}
                >
                  <Button>Upload Image</Button>
                </Upload>
              </Form.Item>

              <Form.Item name="positiveMark" label="Positive Mark">
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item name="negativeMark" label="Negative Mark">
                <InputNumber min={0.0} />
              </Form.Item>

              <Form.Item
                name="cost"
                label={<span>Exam Cost</span>}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value || value <= 10000) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Cost should be less than or equal to 10,000 Rs')
                      );
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" shape="round" size="large" htmlType="submit">
                  Update exam
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default EditExam;
