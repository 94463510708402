import { BookOutlined, DatabaseOutlined, TeamOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Statistic } from 'antd';
import React from 'react';
// import useSWR from 'swr';
import '../../App.css';
// import { useAuth } from '../Auth/AuthProvider';
import { useRequest } from '../Auth/useRequest';
import NotificationForm from './NotificationForm';

function AdminDashboard() {
  // const { MakeRequest } = useAuth();

  // const ExamListRequest = MakeRequest('GET', '/user/exam/enrolls');
  // const { data: exam = {} } = useSWR('/user/exam/enrolls', ExamListRequest);
  // const { data: { exams = [] } = {} } = exam;

  const res = useRequest('GET', '/dashboard/counts');

  const {
    data: {
      totalAdmins,
      totalCourses,
      totalDraftCourses,
      totalDraftExams,
      totalExams,
      totalFreeCourses,
      totalFreeExams,
      totalInstructors,
      totalPremiumCourses,
      totalPremiumExams,
      totalPublishedCourses,
      totalPublishedExams,
      totalRegisteredUsers,
      totalStudents,
      usersInLast24Hours,
    } = {},
  } = res;

  return (
    <>
      <div>
        <Divider orientation="left">Dashboard</Divider>
        <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
          <Col className="dashboard-col-style" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card>
              <Statistic
                title="Total Registered Users"
                value={totalRegisteredUsers}
                prefix={<TeamOutlined />}
              />
              <Card type="inner">
                <Statistic title="Admins" value={totalAdmins} />
                <Statistic title="Instructors" value={totalInstructors} />
                <Statistic title="Students" value={totalStudents} />
                <Statistic title="Users in last 24 Hrs" value={usersInLast24Hours} />
              </Card>
            </Card>
          </Col>
          <Col className="dashboard-col-style" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card>
              <Statistic title="Total Courses" value={totalCourses} prefix={<DatabaseOutlined />} />
              <Card type="inner">
                <Statistic title="Premium" value={totalPremiumCourses} />
                <Statistic title="Free" value={totalFreeCourses} />
                <Statistic title="Published" value={totalPublishedCourses} />
                <Statistic title="Draft" value={totalDraftCourses} />
              </Card>
            </Card>
          </Col>
          <Col className="dashboard-col-style" xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card>
              <Statistic title="Total Exams" value={totalExams} prefix={<BookOutlined />} />
              <Card type="inner">
                <Statistic title="Premium" value={totalPremiumExams} />
                <Statistic title="Free" value={totalFreeExams} />
                <Statistic title="Published" value={totalPublishedExams} />
                <Statistic title="Draft" value={totalDraftExams} />
              </Card>
            </Card>
          </Col>
        </Row>
        <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
          <Col className="dashboard-col-style" xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card>
              <NotificationForm />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdminDashboard;
