import React from 'react';
import { Row, Col, Divider } from 'antd';
import LoginForm from '../components/LoginForm';
import logo from '../assets/purple.png';

function LoginPage() {
  return (
    <Row justify="space-around">
      <Col xs={20} sm={16} md={12} lg={8} xl={5} align="center">
        <img src={logo} alt="logo" className="logo" />
        <Divider orientation="left">Login</Divider>
        <LoginForm />
      </Col>
    </Row>
  );
}

export default LoginPage;
