import React from 'react';
import { Menu, Dropdown, Space, Table, Button, Divider, Modal } from 'antd';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useAuth } from '../Auth/AuthProvider';

const { confirm } = Modal;

function updateCourseStatus(courseList, courseId, status, mutateFunction) {
  const updatedList = courseList.map(singleCourse => {
    if (singleCourse.id === parseInt(courseId, 10)) {
      return {
        ...singleCourse,
        status,
      };
    }

    return singleCourse;
  });

  mutateFunction({
    data: {
      courses: [...updatedList],
    },
  });
}

function updateCourseList(courseList, courseId, mutateFunction) {
  const updatedList = courseList.filter(singleCourse => {
    return singleCourse !== courseId;
  });

  mutateFunction({
    data: {
      courses: [...updatedList],
    },
  });
}

function CourseList(props) {
  const { courses, mutateAllCourse } = props;
  const { MakeRequest } = useAuth();
  const history = useHistory();

  const courseStatus = MakeRequest('POST', '/course/status/update');

  const handleDelete = async id => {
    const deleteCourseRequest = MakeRequest('DELETE', `/delete/course/${id}`);
    await deleteCourseRequest();
    updateCourseList(courses, id, mutateAllCourse);
    Modal.success({
      content: `Course Deleted Successfully`,
    });
  };

  const showConfirm = id => {
    confirm({
      title: 'Sure to delete?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Everything associated with the course (including enrolls and syllabus) will be deleted.',
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };

  const takeActions = async ({ key }) => {
    const [action, id] = key.split(',');

    switch (action) {
      case 'publish':
        updateCourseStatus(courses, id, 'published', mutateAllCourse);
        await courseStatus({ status: 'published', course_id: id });
        break;
      case 'draft':
        updateCourseStatus(courses, id, 'draft', mutateAllCourse);
        await courseStatus({ status: 'draft', course_id: id });
        break;

      case 'viewAndEdit':
        history.push(`/course/${id}`);
        break;

      case 'enrolls':
        history.push(`/course-enrolls/${id}`);
        break;

      case 'delete':
        showConfirm(id);
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'Educator',
      dataIndex: 'instructor',
      render: instructor => {
        return instructor.display_name;
      },
    },

    {
      title: 'Duration(Hrs)',
      dataIndex: 'no_of_days',
      key: 'duration',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },

    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'Sdate',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            overlay={() => (
              <>
                <Menu onClick={takeActions}>
                  <Menu.Item key={`viewAndEdit,${record.id}`}>View and Edit</Menu.Item>

                  {record.status === 'draft' ? (
                    <Menu.Item key={`publish,${record.id}`}>Publish</Menu.Item>
                  ) : (
                    <Menu.Item key={`draft,${record.id}`}>Draft</Menu.Item>
                  )}
                  {record.cost > 0 ? (
                    <Menu.Item key={`enrolls,${record.id}`}>Enrolls</Menu.Item>
                  ) : null}

                  <Menu.Item key={`delete,${record.id}`}>Delete</Menu.Item>
                </Menu>
              </>
            )}
          >
            <Button
              type="link"
              href="#"
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
            >
              Actions &nbsp;
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Divider orientation="left">Courses</Divider>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={courses}
        scroll={{ x: 360 }}
        // expandable={{
        //   expandedRowRender: record => {
        //     return (
        //       <>
        //         {record.curriculums.length ? (
        //           <CurriculumList course={record} role={role} />
        //         ) : (
        //           <>
        //             <Divider orientation="left">
        //               No Syllabus found for&nbsp;
        //               {record.title}
        //             </Divider>

        //             {/* <Divider orientation="left"> */}
        //             <AddCurriculum course={record} />
        //             {/* </Divider> */}
        //           </>
        //         )}
        //       </>
        //     );
        //   },
        // }}
      />
    </>
  );
}

export default CourseList;
