import React from 'react';
import { Table, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function StudentPremiumExamList(props) {
  const { premiumExams } = props;
  const history = useHistory();

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'startDate',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },

    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'endDate',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },

    {
      title: 'Cost(Rs)',
      dataIndex: 'cost',
      key: 'cost',
    },

    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        return <Button onClick={() => history.push(`/premium/exam/${record.id}`)}>View</Button>;
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={premiumExams}
        loading={premiumExams.length}
        scroll={{ x: 360 }}
      />
    </>
  );
}

export default StudentPremiumExamList;
