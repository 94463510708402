import React from 'react';
import {
  Form,
  Select,
  InputNumber,
  DatePicker,
  Button,
  Upload,
  Input,
  Row,
  Col,
  Modal,
} from 'antd';
import { useAuth } from './Auth/AuthProvider';
import notify from '../utils/Notify';

const URL = process.env.REACT_APP_BACKEND_API_PATH;

function CreateNewExamForm(props) {
  const { courses, exams, mutateAllExam } = props;

  const { MakeRequest, AuthState } = useAuth();
  const { token } = AuthState;

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };
  const onFinish = async examData => {
    const AddNewExam = MakeRequest('POST', '/exam/create');
    const {
      title,
      startDate,
      endDate,
      examDuration,
      course,
      uploadImage = [],
      positiveMark,
      negativeMark,
      cost,
    } = examData;
    const formattedData = {
      title,
      start_date: startDate.format(),
      end_date: endDate.format(),
      course_id: course,
      exam_duration: examDuration,
      image_url: uploadImage.length ? uploadImage[0].response.url : '',
      positive_mark: positiveMark,
      negative_mark: negativeMark,
      cost,
    };

    const response = await AddNewExam(formattedData);
    if (response.type === 'error') {
      // eslint-disable-next-line no-restricted-syntax
      // for (const [error] of Object.values(response.data)) {
      notify({ type: 'error', message: 'error. check if the title is unique' });
      // }
    } else if (response.type === 'success') {
      const examToBeAdded = {
        title: examData.title,
        start_date: examData.startDate.format(),
        end_date: examData.endDate.format(),
        exam_duration: examData.examDuration,
        course_id: examData.course,
        image_url: examData.uploadImage,
        positive_mark: examData.positiveMark,
        negative_mark: examData.negativeMark,
      };

      mutateAllExam({
        data: {
          exams: [examToBeAdded, ...exams],
        },
      });
      handleClose();

      Modal.success({
        content: `New Exam ${examData.title} Created`,
      });
    }
  };

  const deleteAsset = async fileObject => {
    const { response } = fileObject;
    const deleteAssetRequest = MakeRequest('DELETE', '/delete/asset');
    await deleteAssetRequest({
      url: response.url,
    });
  };

  return (
    <>
      <Button
        variant="contained"
        className="btnStyle"
        shape="round"
        color="primary"
        onClick={handleOpen}
      >
        Create New Exam
      </Button>
      <Modal title="Create New Exam" visible={visible} onCancel={handleClose} footer={null}>
        <Form layout="vertical" name="addNewExam" onFinish={onFinish} scrollToFirstError>
          <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }} justify="center">
            <Col xs={24} sm={12} md={12} lg={18} xl={18}>
              <Form.Item
                name="title"
                label={<span>Title</span>}
                rules={[
                  {
                    required: true,
                    message: 'Please enter exam title',
                    whitespace: true,
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value.length >= 4) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Title should be more than 2 characters'));
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="startDate"
                label="Start Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>

              <Form.Item
                name="endDate"
                label="End Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker showTime />
              </Form.Item>

              <Form.Item name="examDuration" label="Exam duration(min)">
                <InputNumber min={10} />
              </Form.Item>

              <Form.Item name="course" label="Course">
                <Select>
                  {courses.map(course => (
                    <Select.Option key={course.id} value={course.id}>
                      {course.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="uploadImage" valuePropName="fileList" getValueFromEvent={normFile}>
                <Upload
                  onRemove={deleteAsset}
                  action={`${URL}/api/upload/asset`}
                  name="file"
                  listType="all"
                  headers={{
                    Authorization: `Bearer ${token}`,
                  }}
                  data={{ file_type: 'exam' }}
                >
                  <Button>Upload Image</Button>
                </Upload>
              </Form.Item>

              <Form.Item name="positiveMark" label="Positive Mark">
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item name="negativeMark" label="Negative Mark">
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item
                name="cost"
                label={<span>Exam Cost</span>}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value || value <= 10000) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Cost should be less than or equal to 10,000 Rs')
                      );
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" shape="round" size="large" htmlType="submit">
                  Add exam
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default CreateNewExamForm;
