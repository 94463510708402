import React, { useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom';
import { Login, GoogleAppLogin } from '../utils/AuthRequests';
import notify from '../utils/Notify';
import { useAuth } from './Auth/AuthProvider';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const LoginForm = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);

  const { setAuthState } = useAuth();

  const onFinish = async loginCredentials => {
    setIsProcessing(true);
    const { type, data } = await Login(loginCredentials);
    if (type === 'error' && data === 'Email is not verified!') {
      notify({ type, message: data });
      setIsProcessing(true);
      setIsEmailVerified(false);
    } else if (type === 'error') {
      notify({ type, message: data });
      setIsProcessing(false);
    } else if (type === 'success') {
      setAuthState(data);
    }
  };

  const responseGoogle = async response => {
    const userData = {
      loginEmail: response.profileObj.email,
      name: response.profileObj.name,
    };
    setIsProcessing(true);
    const { type, data } = await GoogleAppLogin(userData);
    if (type === 'error') {
      notify({ type, message: data });
      setIsProcessing(false);
    } else if (type === 'success') {
      setAuthState(data);
    }
  };

  const responseErrorGoogle = response => {
    const type = 'error';
    notify({ type, message: response.error });
  };

  return (
    <>
      <br />
      <div>
        <GoogleLogin
          clientId={googleClientId}
          buttonText="LOGIN WITH GOOGLE"
          onSuccess={responseGoogle}
          onFailure={responseErrorGoogle}
          theme="dark"
        />
      </div>
      <br />
      New Account?&nbsp;
      <Link to="/register">
        <Button type="primary" shape="round" size="large">
          Click Here To Register
        </Button>
      </Link>
      <br />
      <p> or </p>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          label="Email:"
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid E-mail ID',
            },
            {
              required: true,
              message: 'Please enter your E-mail ID',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password:"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },

            () => ({
              validator(_, value) {
                if (!value || value.length >= 8) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Password should be more than 8 characters'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            disabled={isProcessing}
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      {isEmailVerified ? null : (
        <Link
          key="Login"
          className="ant-btn ant-btn-secondary ant-btn-lg"
          to="/resend/email/verification"
        >
          Verify Your Email
        </Link>
      )}
    </>
  );
};

export default LoginForm;
