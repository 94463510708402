import React from 'react';
import { Form, Button, Input, Row, Col, Modal, Radio } from 'antd';
import { EditFilled, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAuth } from './Auth/AuthProvider';
import notify from '../utils/Notify';

function updateExam(exam, id, question, mutateFunction) {
  const updatedExamwithQuestions = exam.questions.map(singleQuestion => {
    if (singleQuestion.id === id) {
      return { ...singleQuestion, question };
    }
    return singleQuestion;
  });

  mutateFunction(updatedExamwithQuestions);
}

function EditQuestion(props) {
  const { question, exam, mutateExam } = props;

  const { MakeRequest } = useAuth();
  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const onFinish = async questionData => {
    const UpdateQuestion = MakeRequest('POST', `/question/update/${question.id}`);
    const { formQuestion, options } = questionData;
    const formattedData = {
      question: formQuestion,
      options,
      exam_id: question.exam_id,
    };

    const response = await UpdateQuestion(formattedData);
    if (response.errors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [error] of Object.values(response.data)) {
        notify({ type: 'error', message: error });
      }
    } else if (response.type === 'success') {
      const updatedExamQuestion = {
        id: question.id,
        question: formQuestion,
        options: questionData.options,
        exam_id: question.exam_id,
      };

      updateExam(exam, question.id, updatedExamQuestion, mutateExam);
      handleClose();
      Modal.success({
        content: `Updated Question ${question.question}`,
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        className="btnStyle"
        shape="round"
        color="primary"
        onClick={handleOpen}
      >
        <EditFilled />
      </Button>
      <Modal
        title="Edit Question"
        visible={visible}
        onCancel={handleClose}
        footer={null}
        width={1000}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form
              layout="vertical"
              name="questionForm"
              initialValues={{
                formQuestion: question.question,
                options: question.options,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="formQuestion"
                label="Question"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.List
                name="options"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <>
                        <Form.Item
                          label={index === 0 ? 'Options' : ''}
                          required={false}
                          key={field.key}
                        >
                          <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                              <Form.Item
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                                name={[field.name, 'optionText']}
                                fieldKey={[field.fieldKey, 'optionText']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: 'Please input option or delete this field.',
                                  },
                                ]}
                                noStyle
                              >
                                <Input placeholder={field.optionText} style={{ width: '60%' }} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                              <Form.Item
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                                name={[field.name, 'isCorrect']}
                                fieldKey={[field.fieldKey, 'isCorrect']}
                                rules={[{ required: true, message: 'Missing Correct or Wrong' }]}
                              >
                                <Radio.Group value={field.isCorrect}>
                                  <Radio value> Correct</Radio>
                                  <Radio value={false}>Wrong</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </Form.Item>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                      >
                        Add options
                      </Button>

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type="primary" shape="round" size="large" htmlType="submit">
                  Update Question
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default EditQuestion;
