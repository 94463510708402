/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Divider, Table, Card } from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import { SmileTwoTone } from '@ant-design/icons';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import AdminExam from '../components/AdminExam';
import QuestionAndAnswersList from '../components/QuestionAndAnswersList';
import SingleQuestion from '../components/SingleQuestion';

function SingleFreeExamPage() {
  const { id } = useParams();

  const { AuthState, MakeRequest } = useAuth();
  const { role } = AuthState;
  const getExamRequest = MakeRequest('GET', `/exam/${id}`);

  const { data: { data = {}, type } = {}, mutate: mutateExam } = useSWR(
    `/exam/${id}`,
    getExamRequest
  );

  const { exam = {} } = data;

  if (!type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (type === 'error') {
    return <AppLayout>Could not find the exam</AppLayout>;
  }

  if (role === 'student' && exam.course && !exam.isCourseEnrolled) {
    return <AppLayout>You need to enroll for the course of this exam</AppLayout>;
  }

  const columns = [
    {
      title: 'Rank',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Total Marks',
      dataIndex: 'total_marks',
      key: 'total_marks',
    },

    {
      title: 'Name',
      dataIndex: 'user',
      key: 'student',
      render: user => {
        return user.display_name;
      },
    },
  ];

  return (
    <>
      <AppLayout>
        {role === 'admin' || role === 'instructor' ? (
          <AdminExam exam={exam} mutateExam={mutateExam} />
        ) : typeof exam.examResult === 'object' &&
          exam.examResult !== null &&
          exam.examResult.top_ten_ranks ? (
          // eslint-disable-next-line react/jsx-indent
          <Row gutter={[16, 8]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Divider orientation="left">Exam: {exam.title}</Divider>
              <Card title="Your Result">
                <span>
                  {moment().isSameOrAfter(exam.end_date) ? null : (
                    <p> Your rank may change as others are still writing.</p>
                  )}
                </span>
                <h3>
                  Rank:&nbsp;
                  {exam.examResult.userRank}
                </h3>
                <h3>
                  Total Marks:&nbsp;
                  {exam.examResult.result.total_marks}
                </h3>
                {exam.examResult.top_ten_ranks.length ? (
                  <>
                    <Divider orientation="left">Top Ten Ranks</Divider>

                    <Table
                      columns={columns}
                      rowKey="id"
                      dataSource={exam.examResult.top_ten_ranks}
                      loading={exam.examResult.top_ten_ranks.length}
                      scroll={{ x: 360 }}
                      pagination={false}
                    />
                  </>
                ) : null}
              </Card>
            </Col>
            {exam.examResult.result.answers ? (
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <QuestionAndAnswersList
                  questions={exam.questions}
                  answers={exam.examResult.result.answers}
                />
              </Col>
            ) : null}
          </Row>
        ) : (
          <>
            <Divider orientation="left">Exam: {exam.title}</Divider>

            {exam.questions.length && moment().isSameOrBefore(exam.end_date) ? (
              <>
                <SingleQuestion exam={exam} />
              </>
            ) : (
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                  <Card
                    title={
                      moment().isSameOrAfter(exam.end_date)
                        ? `Too late! This Exam has ended.`
                        : `You are Enrolled to this exam`
                    }
                  >
                    <h3>Starts On: {moment(exam.start_date).format('Do MMM YY hh:mm A')}</h3>
                    <h3>Ends On: {moment(exam.end_date).format('Do MMM YY hh:mm A')}</h3>
                    <h3>
                      Duration: {exam.exam_duration}
                      &nbsp; minutes
                    </h3>
                    <h3>
                      Negative Mark: &nbsp;
                      {exam.negative_mark}
                    </h3>
                    <p>
                      Do not miss on timings. Good Luck&nbsp; <SmileTwoTone />
                    </p>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
      </AppLayout>
    </>
  );
}

export default SingleFreeExamPage;
