/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Divider, Button, Card, Space, Dropdown, Menu } from 'antd';
import useSWR from 'swr';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';

function SingleUserPage() {
  const { id } = useParams();
  const { MakeRequest, AuthState } = useAuth();
  const { role } = AuthState;
  const history = useHistory();
  const getUserRequest = MakeRequest('GET', `/user/show/${id}`);

  const { data: { data = {}, type } = {}, mutate: mutateUser } = useSWR(
    `/user/show/${id}`,
    getUserRequest
  );

  const { user = {} } = data;

  if (!type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (type === 'error') {
    return <AppLayout>Could not find the user</AppLayout>;
  }

  const userRole = MakeRequest('PATCH', '/user/role/update');

  const takeActions = async ({ key }) => {
    const [action, userId] = key.split(',');

    switch (action) {
      case 'instructor':
        await userRole({ role: 'instructor', user_id: userId });
        mutateUser({ ...user, role: 'instructor' });

        break;
      case 'student':
        await userRole({ role: 'student', user_id: userId });
        mutateUser({ ...user, role: 'student' });

        break;
      case 'admin':
        await userRole({ role: 'admin', user_id: userId });
        mutateUser({ ...user, role: 'admin' });

        break;

      default:
        break;
    }
  };

  return (
    <AppLayout>
      {role === 'admin' ? (
        <>
          <Button
            type="primary"
            shape="round"
            htmlType="link"
            onClick={() => history.push(`/manage-users`)}
            className="switchBtnStyle"
          >
            Back To User List
          </Button>
          &nbsp;
          <Divider orientation="left">
            User:&nbsp;
            {user.display_name}
          </Divider>
          <Row>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Card
                title={`${user.display_name} Details`}
                extra={
                  <Space size="middle">
                    <Dropdown
                      overlay={() => (
                        <Menu onClick={takeActions}>
                          <Menu.Item disabled={user.role === 'student'} key={`student,${user.id}`}>
                            Make Student
                          </Menu.Item>
                          <Menu.Item
                            disabled={user.role === 'instructor'}
                            key={`instructor,${user.id}`}
                          >
                            Make Instructor
                          </Menu.Item>
                          <Menu.Item disabled={user.role === 'admin'} key={`admin,${user.id}`}>
                            Make Admin
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        type="link"
                        href="#"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        Actions &nbsp;
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                }
                style={{ overflowWrap: 'break-word' }}
              >
                <h3>
                  Name: &nbsp;
                  <strong>{user.display_name}</strong>
                </h3>
                <h3>
                  Email: &nbsp;
                  <strong>{user.email}</strong>
                </h3>
                <h3>
                  Designation: &nbsp;
                  <strong>{user.role}</strong>
                </h3>
                <h3>
                  Phone Number: &nbsp;
                  <strong>{user.phone_number || `Not Given`}</strong>
                </h3>
                <h3>
                  Account Created On: &nbsp;
                  <strong>{moment(user.created_at).format('Do MMM YY hh:mm A')}</strong>
                </h3>
                <h3>
                  Account Updated On: &nbsp;
                  <strong>{moment(user.updated_at).format('Do MMM YY hh:mm A')}</strong>
                </h3>
                <h3>
                  Email Verified On: &nbsp;
                  <strong>
                    {moment(user.email_verified_at).format('Do MMM YY hh:mm A') ||
                      `Not Yet Verified`}
                  </strong>
                </h3>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Divider orientation="left">
            Subject:&nbsp;
            {user.title}
          </Divider>
        </>
      )}

      {role === 'student' ? <h3>{user.title}</h3> : null}
    </AppLayout>
  );
}

export default SingleUserPage;
