import { Divider } from 'antd';
import React, { useEffect } from 'react';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import { deleteLocalStorage } from '../utils/localStorage';

function LogoutPage() {
  const { MakeRequest, setAuthState, initialAuthState } = useAuth();

  useEffect(() => {
    deleteLocalStorage('auth_details');
    const logout = MakeRequest('POST', '/user/logout');
    setAuthState(initialAuthState);
    logout();
  }, []);
  return (
    <AppLayout>
      <Divider orientation="left">Logging out, please wait...</Divider>
    </AppLayout>
  );
}

export default LogoutPage;
