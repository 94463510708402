/* eslint-disable no-nested-ternary */
import {
  Button,
  Input,
  Form,
  DatePicker,
  InputNumber,
  Result,
  Select,
  Col,
  Row,
  Modal,
} from 'antd';
import React from 'react';
import useSWR from 'swr';
import notify from '../utils/Notify';
import { getEmbedableSource } from '../utils/Utility';
import { useAuth } from './Auth/AuthProvider';

function AddCurriculum(props) {
  const { course, mutateCourse, buttonText } = props;
  const { MakeRequest, AuthState } = useAuth();
  const { role } = AuthState;
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setIsProcessing(false);
  };
  const instructorsRequest = MakeRequest('GET', '/instructors');

  const { data: instructor = {} } = useSWR('/instructors', instructorsRequest);
  const { data: { instructors = [] } = {} } = instructor;

  const onFinish = async addCurriculumData => {
    setIsProcessing(true);

    const addCurriculum = MakeRequest('POST', `/curriculum/create`);
    const {
      title,
      duration,
      curriculumDate,
      description,
      video,
      educator,
      streamembedcode,
      chatembedcode,
    } = addCurriculumData;
    const formattedData = {
      title,
      no_of_hours: duration,
      curriculum_date: curriculumDate.add(330, 'minutes').format(),
      description,
      course_id: course.id,
      instructor_id: educator,
      event_embed_code: getEmbedableSource(streamembedcode),
      chat_embed_code: getEmbedableSource(chatembedcode),
      video: getEmbedableSource(video),
    };
    const response = await addCurriculum(formattedData);
    if (response.type === 'error') {
      // eslint-disable-next-line no-restricted-syntax
      for (const [error] of Object.values(response.data)) {
        notify({ type: 'error', message: error });
      }
      setIsProcessing(false);
    } else if (response.type === 'success') {
      const curriculumToBeAdded = {
        id: response.data.id,
        title,
        no_of_hours: duration,
        curriculum_date: curriculumDate.add(330, 'minutes').format(),
        description,
        course_id: course.id,
        instructor_id: educator,
        event_embed_code: getEmbedableSource(streamembedcode),
        chat_embed_code: getEmbedableSource(chatembedcode),
        video: getEmbedableSource(video),
        topics: [],
      };
      mutateCourse({
        data: {
          curriculums: [curriculumToBeAdded, ...course.curriculums],
        },
      });
      Modal.success({
        content: `Subject ${title} Created`,
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        className="syllabusBtnStyle"
        color="primary"
        shape="round"
        onClick={handleOpen}
      >
        {buttonText}
      </Button>
      <Modal
        title="Add New Subject to Syllabus"
        visible={visible}
        onCancel={handleClose}
        footer={null}
      >
        {isProcessing === 'success' ? (
          <Result status="success" title="Success Adding Subject to the Syllabus" />
        ) : (
          <Form layout="vertical" name="addNewCurriculum" onFinish={onFinish}>
            <Form.Item name="title" label="Subject Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="description"
                  label="Subject Description"
                  rules={[
                    {
                      required: true,
                    },
                    () => ({
                      validator(_, value) {
                        if (!value || (value.length >= 4 && value.length <= 200)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Description should be 4 to 200 chracters')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item
                  name="curriculumDate"
                  label="Subject Start Date"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
                <Form.Item
                  name="duration"
                  label="Subject Duration (hrs)"
                  rules={[
                    {
                      type: 'number',
                      min: 1,
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>

                {role === 'admin' ? (
                  instructors.length ? (
                    <Form.Item name="educator" label="Educator">
                      <Select>
                        {instructors.map(singleInstructor => (
                          <Select.Option key={singleInstructor.id} value={singleInstructor.id}>
                            {singleInstructor.display_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                ) : null}
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="streamembedcode" label="Stream Embed Code">
                  <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item name="chatembedcode" label="Chat Embed Code">
                  <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item name="video" label="Youtube Embed Code">
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
}

export default AddCurriculum;
