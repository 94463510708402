/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Table } from 'antd';
import useSWR from 'swr';
import moment from 'moment';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';

function ExamResultsPage() {
  const { id } = useParams();
  const { MakeRequest, AuthState } = useAuth();
  const { role } = AuthState;
  const [resultPage, setResultPage] = React.useState(1);

  const ExamListRequest = MakeRequest('GET', `/exam/results/${id}`);
  const { data: res = {} } = useSWR(`/exam/results/${id}`, ExamListRequest);
  const { data: { exam = {} } = {} } = res;

  if (!res.type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (res.type === 'error') {
    return <AppLayout>Could not find the course</AppLayout>;
  }

  const columns = [
    {
      title: 'Rank',
      key: 'index',
      render: (value, item, index) => {
        return (resultPage - 1) * 10 + index + 1;
      },
    },

    {
      title: 'Name',
      dataIndex: 'user',
      render: user => {
        return user.display_name;
      },
    },

    {
      title: 'Total Marks',
      dataIndex: 'total_marks',
      key: 'total_marks',
    },

    {
      title: 'Email',
      dataIndex: 'user',
      render: user => {
        return user.email;
      },
    },

    {
      title: 'Phone No',
      dataIndex: 'user',
      render: user => {
        return user.phone_number;
      },
    },

    {
      title: 'Finished On',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => {
        const dateTime = moment(text).format('Do MMM YY hh:mm A');
        return dateTime;
      },
    },
  ];

  return (
    <AppLayout>
      {role === 'admin' || role === 'instructor' ? (
        <>
          <Divider orientation="left">Exam: {exam.title}</Divider>
          <Divider orientation="left">Students Result</Divider>
          <Table
            columns={columns}
            rowKey="id"
            dataSource={exam.exam_results}
            scroll={{ x: 360 }}
            pagination={{
              onChange(current) {
                setResultPage(current);
              },
            }}
          />
        </>
      ) : (
        <Divider orientation="left">Not Authorized</Divider>
      )}
    </AppLayout>
  );
}

export default ExamResultsPage;
