import { Col, Row } from 'antd';
import React from 'react';
import logo from '../assets/purple.png';
import ResendVerificationForm from '../components/ResendVerificationForm';

function ResendEmailVerificationPage() {
  return (
    <Row justify="space-around">
      <Col xs={20} sm={16} md={12} lg={8} xl={5} align="center">
        <img src={logo} alt="logo" className="logo" />
        <ResendVerificationForm />
      </Col>
    </Row>
  );
}

export default ResendEmailVerificationPage;
