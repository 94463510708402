import React from 'react';
import { Menu, Dropdown, Space, Table, Button, Divider } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useAuth } from '../Auth/AuthProvider';

function updateUserRole(userList, userId, role, mutateFunction) {
  const updatedList = userList.map(singleUser => {
    if (singleUser.id === parseInt(userId, 10)) {
      return {
        ...singleUser,
        role,
      };
    }

    return singleUser;
  });

  mutateFunction({
    data: {
      users: [...updatedList],
    },
  });
}

function AdminUserList(props) {
  const { users, mutateAllUser } = props;

  const { MakeRequest } = useAuth();
  const userRole = MakeRequest('PATCH', '/user/role/update');

  const takeActions = async ({ key }) => {
    const [action, id] = key.split(',');

    switch (action) {
      case 'instructor':
        updateUserRole(users, id, 'instructor', mutateAllUser);
        await userRole({ role: 'instructor', user_id: id });
        break;
      case 'student':
        updateUserRole(users, id, 'student', mutateAllUser);
        await userRole({ role: 'student', user_id: id });
        break;
      case 'admin':
        updateUserRole(users, id, 'admin', mutateAllUser);
        await userRole({ role: 'admin', user_id: id });
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'display_name',
      key: 'name',
    },

    {
      title: 'Designation',
      dataIndex: 'role',
      key: 'role',
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            overlay={() => (
              <Menu onClick={takeActions}>
                <Menu.Item disabled key={`edit,${record.id}`}>
                  Edit
                </Menu.Item>
                <Menu.Item disabled key={`delete,${record.id}`}>
                  Delete
                </Menu.Item>
                <Menu.Item disabled={record.role === 'student'} key={`student,${record.id}`}>
                  Make Student
                </Menu.Item>
                <Menu.Item disabled={record.role === 'instructor'} key={`instructor,${record.id}`}>
                  Make Instructor
                </Menu.Item>
                <Menu.Item disabled={record.role === 'admin'} key={`admin,${record.id}`}>
                  Make Admin
                </Menu.Item>
              </Menu>
            )}
          >
            <Button
              type="link"
              href="#"
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
            >
              Actions &nbsp;
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Divider orientation="left">Users</Divider>
      <Table columns={columns} rowKey="id" dataSource={users} scroll={{ x: 360 }} />
    </>
  );
}

export default AdminUserList;
