import useSWR from 'swr';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AutoComplete, Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminCourseList from '../components/AdminDashboard/AdminCourseList';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import CreateNewFreeClassForm from '../components/CreateNewFreeClassForm';

function ManageClassesPage() {
  const history = useHistory();
  const { MakeRequest } = useAuth();
  const CourseListRequest = MakeRequest('GET', '/user/courses/all');
  const { data: course = {}, mutate: mutateAllCourse } = useSWR(
    '/user/courses/all',
    CourseListRequest
  );
  const { data: { courses = [] } = {} } = course;

  const [options, setOptions] = useState([{ value: '', id: null }]);

  const onSelect = (value, option) => {
    history.push(`/course/${option.id}`);
  };
  const onFocus = () => {
    const results = courses.map(x => {
      return { value: x.title, id: x.id };
    });
    setOptions(results);
  };

  return (
    <AppLayout>
      <Helmet>
        <title>Manage classes</title>
      </Helmet>
      <Row>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <CreateNewFreeClassForm courses={courses || []} mutateAllCourse={mutateAllCourse} />
        </Col>
        {courses.length ? (
          <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginInline: 'auto' }}>
            <AutoComplete
              style={{ width: 320 }}
              placeholder="Search Course"
              options={options}
              onSelect={onSelect}
              onFocus={onFocus}
              filterOption={
                (inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </Col>
        ) : null}
      </Row>

      <AdminCourseList courses={courses || []} mutateAllCourse={mutateAllCourse} />
    </AppLayout>
  );
}
export default ManageClassesPage;
