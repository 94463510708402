import Axios from 'axios';
import React, { createContext, useState, useContext } from 'react';
import { getLocalStorage } from '../../utils/localStorage';

const authDetails = getLocalStorage('auth_details');
const initialAuthState = {
  displayName: '',
  isLoggedIn: false,
  token: '',
  role: 'student',
};

const Auth = createContext();

function AuthProvider({ children }) {
  const [AuthState, setAuthState] = useState(authDetails || initialAuthState);

  const MakeRequest = (method, url) => {
    Axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

    const { token } = AuthState;

    return async data => {
      const request = Axios.create();

      const response = await request({
        method,
        url,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: data ? JSON.stringify(data) : undefined,
      });

      return { type: 'success', data: response.data };
    };
  };

  return (
    <Auth.Provider value={{ AuthState, setAuthState, MakeRequest, initialAuthState }}>
      {children}
    </Auth.Provider>
  );
}

export const useAuth = () => useContext(Auth);

export default AuthProvider;
