import React from 'react';
import '../../App.css';
import { Card, Divider, List } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import useSWR from 'swr';
import { useAuth } from '../Auth/AuthProvider';

function Latest() {
  const { MakeRequest } = useAuth();

  const CourseListRequest = MakeRequest('GET', '/courses/latest');
  const { data: course = {} } = useSWR('/courses/latest', CourseListRequest);
  const { data: { courses = [] } = {} } = course;
  return (
    <>
      <div style={{ textAlign: 'left' }}>
        <Divider orientation="left">Latest from Officers Adda: </Divider>
        <Card bordered={false} style={{ width: 300 }}>
          <List
            itemLayout="horizontal"
            dataSource={courses}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={`${moment(item.start_date).format('Do MMM YY hh:mm A')}`}
                />
              </List.Item>
            )}
          />
          <Link to="./free-course">View more &gt;</Link>
        </Card>
      </div>
    </>
  );
}

export default Latest;
