import React from 'react';
import { Result, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

function EmailVerified() {
  return (
    <Row justify="space-around">
      <Col xs={20} sm={16} md={12} lg={8} xl={7} align="center">
        <Result
          status="success"
          title="Email Address Verified"
          extra={[
            <Link key="Login" className="ant-btn ant-btn-primary" to="/login">
              Login
            </Link>,
          ]}
        />
      </Col>
    </Row>
  );
}

export default EmailVerified;
