import { Button, Input, Form, DatePicker, InputNumber, Result, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import notify from '../utils/Notify';
import { useAuth } from './Auth/AuthProvider';

function EditTopic(props) {
  const { topic, mutateTopic } = props;

  const { MakeRequest } = useAuth();
  const [isProcessing, setIsProcessing] = React.useState(false);

  const onFinish = async editTopicData => {
    setIsProcessing(true);

    const editTopic = MakeRequest('POST', `/topic/update/${topic.id}`);
    const { title, duration, topicDate, description, video } = editTopicData;
    const formattedData = {
      title,
      no_of_hours: duration,
      topic_date: topicDate.add(330, 'minutes').format(),
      description,
      curriculum_id: topic.curriculum_id,
      video,
    };
    const response = await editTopic(formattedData);
    if (response.errors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [error] of Object.values(response.data)) {
        notify({ type: 'error', message: error });
      }
      setIsProcessing(false);
    } else if (response.type === 'success') {
      const updatedTopic = {
        title,
        no_of_hours: duration,
        topic_date: topicDate.add(330, 'minutes').format(),
        description,
        curriculum_id: topic.curriculum_id,
        video,
      };
      mutateTopic({ ...topic, updatedTopic });
      setIsProcessing(false);

      Modal.success({
        content: `${title} Topic Updated`,
      });
    }
  };

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setIsProcessing(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        shape="round"
        onClick={handleOpen}
        className="topicBtnStyle"
      >
        Edit Topic
      </Button>
      <Modal title="Edit Topic" visible={visible} onCancel={handleClose} footer={null}>
        {isProcessing === 'success' ? (
          <Result status="success" title="Updated Topic" />
        ) : (
          <Form
            layout="vertical"
            name="addNewCurriculum"
            onFinish={onFinish}
            initialValues={{
              title: topic.title,
              description: topic.description,
              duration: topic.no_of_hours,
              topicDate: moment(topic.topic_date),
              video: topic.video,
            }}
          >
            <Form.Item name="title" label="Topic Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Topic Description">
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item
              name="topicDate"
              label="Topic Start Date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
            </Form.Item>
            <Form.Item
              name="duration"
              label="Topic Duration (hrs)"
              rules={[
                {
                  type: 'number',
                  min: 1,
                  required: true,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item name="video" label="Youtube Embed Code">
              <Input.TextArea rows={3} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
}

export default EditTopic;
