import React from 'react';
import { Result, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

function EmailVerificationError() {
  return (
    <Row justify="space-around">
      <Col xs={20} sm={16} md={12} lg={8} xl={7} align="center">
        <Result
          status="error"
          title="Email Verification Failed"
          subTitle="This verification link is either invalid or has expired. Please request a new verification link!"
          extra={[
            <Link
              key="Resend Verification Link"
              className="ant-btn ant-btn-primary"
              to="/resend/email/verification"
            >
              Resend Verification Link
            </Link>,
          ]}
        />
      </Col>
    </Row>
  );
}

export default EmailVerificationError;
