const auth = {
  AuthState: {
    displayName: '',
    isLoggedIn: false,
    token: '',
    role: 'student',
    email: '',
  },
  setAuthState: () => {},
};

export function getAuth({ AuthState, setAuthState }) {
  auth.AuthState = AuthState;
  auth.setAuthState = setAuthState;
}

export default auth;
