/* eslint-disable no-console */
import { notification } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import axios from 'axios';
import App from './App';
import AuthProvider from './components/Auth/AuthProvider';
import reportWebVitals from './reportWebVitals';
import 'firebase/messaging';

// eslint-disable-next-line prefer-destructuring
const REACT_APP_VAPID_PUBLIC_KEY = process.env.REACT_APP_VAPID_PUBLIC_KEY;
// eslint-disable-next-line prefer-destructuring
const REACT_APP_BACKEND_API_PATH = process.env.REACT_APP_BACKEND_API_PATH;

const firebaseConfig = {
  apiKey: 'AIzaSyAGslQspBmIDXubSM893mwmVyCDxJRSr9w',
  authDomain: 'officer-adda-dev.firebaseapp.com',
  projectId: 'officer-adda-dev',
  storageBucket: 'officer-adda-dev.appspot.com',
  messagingSenderId: '963312536300',
  appId: '1:963312536300:web:17ed727c2f61d8a2aab3c9',
};
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('firebase-messaging-sw.js')
    .then(reg => {
      messaging
        .getToken({
          reg,
          vapidKey: REACT_APP_VAPID_PUBLIC_KEY,
        })
        .then(async currentToken => {
          if (currentToken) {
            axios
              .post(`${REACT_APP_BACKEND_API_PATH}/api/add/notification/device`, {
                endpoint: currentToken,
              })
              .then(() => {
                console.log('subscribed for notifications');
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            const args = {
              message: 'Notifications blocked.',
              description: 'Please allow notifications to get notified of latest from officersadda',
              duration: 0,
            };
            notification.open(args);
          }
        })
        .catch(err => {
          const args = {
            message: 'Notifications blocked.',
            description:
              'Please allow notifications in settings to get notified of latest from officersadda',
            duration: 0,
          };
          notification.open(args);
          console.log('An error occurred while retrieving token. ', err);
        });

      // eslint-disable-next-line no-param-reassign
      reg.onupdatefound = () => {
        const installingWorker = reg.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // const btn = (
              //   <Button
              //     type="primary"
              //     size="small"
              //     onClick={() => {
              //       if (reg && reg.waiting) {
              //         reg.waiting.postMessage({ type: 'SKIP_WAITING' });
              //       }
              //       window.location.reload();
              //     }}
              //   >
              //     Update
              //   </Button>
              // );
              // const args = {
              //   message: 'New version available!',
              //   description: 'Click update to see new features in the app',
              //   duration: 0,
              //   btn,
              // };
              // notification.open(args);
              if (reg && reg.waiting) {
                reg.waiting.postMessage({ type: 'SKIP_WAITING' });
              }
              window.location.reload();
            } else {
              console.log('Content is cached for offline use.');
            }
          }
        };
      };
    })
    .catch(error => {
      console.log(`Registration failed with ${error}`);
    });
}
