import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import DashboardPage from './pages/DashboardPage';
import 'antd/dist/antd.css';
import './App.css';

import RegistrationPage from './pages/RegistrationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import ResendEmailVerificationPage from './pages/ResendEmailVerificationPage';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth } from './components/Auth/AuthProvider';
import { getAuth } from './utils/getAuthDetails';
import ManageClassesPage from './pages/ManageClassesPage';
import LogoutPage from './pages/LogoutPage';
import FreeCoursePage from './pages/Student/FreeCoursePage';
import SingleCoursePage from './pages/SingleCoursePage';
import ManageExamsPage from './pages/ManageExamsPage';
import ExamPage from './pages/Student/ExamPage';
import SingleExamPage from './pages/SingleExamPage';
import PremiumCoursesPage from './pages/PremiumCoursesPage';
import PremiumCourses from './components/premiumCourses/PremiumCourses';
import PremiumExamsPage from './pages/PremiumExamsPage';
// import PremiumExamsPage from './pages/PremiumExamsPage';
import CreateSettingsPage from './pages/CreateSettingsPage';
import ManageUsersPage from './pages/ManageUsersPage';
import SingleCurriculumPage from './pages/SingleCurriculumPage';
import SingleTopicPage from './pages/SingleTopicPage';
import CourseEnrollsPage from './pages/CourseEnrollsPage';
import SingleFreeExamPage from './pages/singleFreeExamPage';
import ExamEnrollspage from './pages/ExamEnrollspage';
import SingleUserPage from './pages/SingleUserPage';
import ExamResultsPage from './pages/ExamResultsPage';

const queryClient = new QueryClient();

function App() {
  const location = useLocation();
  const { AuthState, setAuthState } = useAuth();

  useEffect(() => {
    getAuth({ AuthState, setAuthState });
  }, [AuthState]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Switch>
          <ProtectedRoute exact path="/" component={DashboardPage} />
          <ProtectedRoute path="/settings" component={CreateSettingsPage} />
          <ProtectedRoute path="/manage-users" component={ManageUsersPage} />
          <ProtectedRoute path="/manage-classes" component={ManageClassesPage} />
          <ProtectedRoute path="/manage-exams" component={ManageExamsPage} />
          <ProtectedRoute path="/free-course" component={FreeCoursePage} />
          <ProtectedRoute path="/premium-course" component={PremiumCoursesPage} />
          <ProtectedRoute path="/premium-exam" component={PremiumExamsPage} />
          <ProtectedRoute path="/course/:id" component={SingleCoursePage} />
          <ProtectedRoute path="/user/:id" component={SingleUserPage} />
          <ProtectedRoute path="/course-enrolls/:id" component={CourseEnrollsPage} />
          <ProtectedRoute path="/exam-results/:id" component={ExamResultsPage} />
          <ProtectedRoute path="/exam-enrolls/:id" component={ExamEnrollspage} />
          <ProtectedRoute path="/curriculum/:id" component={SingleCurriculumPage} />
          <ProtectedRoute path="/topic/:id" component={SingleTopicPage} />
          <ProtectedRoute path="/premium/course/:id" component={PremiumCourses} />
          <ProtectedRoute exact path="/exam" component={ExamPage} />
          <ProtectedRoute path="/exam/:id" component={SingleFreeExamPage} />
          <ProtectedRoute path="/premium/exam/:id" component={SingleExamPage} />
          <ProtectedRoute path="/logout" component={LogoutPage} />
          <Route path="/reset-password/:email/:token" component={ResetPasswordPage} />
          <Route path="/verify-email/:id/:expires/:signature" component={EmailVerificationPage} />
          {AuthState.isLoggedIn ? (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: location.pathname,
                },
              }}
            />
          ) : (
            <>
              <Route path="/resend/email/verification" component={ResendEmailVerificationPage} />
              <Route path="/register" component={RegistrationPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/forgot-password" component={ForgotPasswordPage} />
            </>
          )}
        </Switch>
      </div>
    </QueryClientProvider>
  );
}

export default App;
