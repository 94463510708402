import React from 'react';
import { Helmet } from 'react-helmet-async';
import AppLayout from '../components/AppLayout';
import { useAuth } from '../components/Auth/AuthProvider';
import Dashboard from '../components/AdminDashboard/AdminDashboard';
import StudentDashboard from '../components/StudentDashboard/StudentDashboard';
import InstructorDashboard from '../components/InstructorDashboard/InstructorDashboard';

function DashboardPage(props) {
  const {
    AuthState: { role },
  } = useAuth();

  const {
    pageMeta: { pageTitle },
  } = props;

  const ConditionalDashboard = () => {
    if (role === 'admin') {
      return <Dashboard />;
    }

    if (role === 'instructor') {
      return <InstructorDashboard />;
    }

    return <StudentDashboard />;
  };

  return (
    <AppLayout>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {ConditionalDashboard()}
    </AppLayout>
  );
}

export default DashboardPage;
