import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteFilled } from '@ant-design/icons';
import { Button, Card, Divider, List, Modal, Popconfirm, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useAuth } from './Auth/AuthProvider';
import EditQuestion from './EditQuestion';

function updateExamQuestions(exam, examId, mutateFunction) {
  const updatedExam = exam.questions.filter(singleQuestion => {
    return singleQuestion.id !== examId;
  });

  mutateFunction(updatedExam);
}

function QuestionsList(props) {
  const { exam, role, mutateExam } = props;
  const { MakeRequest } = useAuth();
  const [questionsPage, setQuestionsPage] = useState(1);

  const deleteQuestion = async (id, title) => {
    const deleteQuestionRequest = MakeRequest('DELETE', `/delete/question/${id}`);
    await deleteQuestionRequest();
    updateExamQuestions(exam, id, mutateExam);
    Modal.success({
      content: `Question ${title} Deleted`,
    });
  };

  return (
    <>
      {exam.questions.length ? (
        <>
          <Divider orientation="left">Questions with Options</Divider>
          <Card>
            <List
              itemLayout="vertical"
              dataSource={exam.questions}
              pagination={{
                onChange(current) {
                  setQuestionsPage(current);
                },
                pageSize: 10,
              }}
              renderItem={(question, index) => (
                <List.Item
                  extra={
                    role === 'admin' || role === 'instructor' ? (
                      <>
                        <>
                          <EditQuestion question={question} exam={exam} mutateExam={mutateExam} />
                          &nbsp;
                          <Tooltip title={`Delete Question ${question.question}`} placement="right">
                            <Popconfirm
                              title="Options Associated with this Question will be deleted too. Sure to delete?"
                              onConfirm={() => deleteQuestion(question.id, question.question)}
                            >
                              <Button shape="round" className="deleteBtnStyle">
                                <DeleteFilled />
                              </Button>
                            </Popconfirm>
                          </Tooltip>
                        </>
                      </>
                    ) : null
                  }
                >
                  <List.Item.Meta
                    title={`${(questionsPage - 1) * 10 + index + 1}.${question.question}`}
                  />
                  {question.options.length ? (
                    question.options.map(option => {
                      return (
                        <Card>
                          {option.isCorrect ? (
                            <>
                              <p>
                                <Tooltip
                                  title={`Correct Answer ${option.optionText}`}
                                  placement="right"
                                >
                                  {option.optionText}
                                  &nbsp;
                                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                                </Tooltip>
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                <Tooltip
                                  title={`Wrong Answer ${option.optionText}`}
                                  placement="right"
                                >
                                  {option.optionText}
                                  &nbsp;
                                  <CloseCircleTwoTone twoToneColor="#eb2f96" />
                                </Tooltip>
                              </p>
                            </>
                          )}
                        </Card>
                      );
                    })
                  ) : (
                    <Divider orientation="left">
                      No Options found in &nbsp;
                      {question.question}
                    </Divider>
                  )}
                </List.Item>
              )}
            />
          </Card>
        </>
      ) : (
        <>
          <Divider orientation="left">No Questions found</Divider>
        </>
      )}
    </>
  );
}

export default QuestionsList;
