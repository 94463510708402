/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Form, Button, Input, notification, Divider } from 'antd';
import axios from 'axios';
import { useAuth } from '../Auth/AuthProvider';

const URL = process.env.REACT_APP_BACKEND_API_PATH;

const NotificationForm = () => {
  const { AuthState } = useAuth();
  const { token } = AuthState;
  const [isProcessing, setIsProcessing] = useState(false);

  const sendNotification = async newNotificationData => {
    setIsProcessing(true);
    const NotificationRequest = axios.create();

    try {
      const NotificationResponse = await NotificationRequest({
        method: 'POST',
        url: `${URL}/api/send/notification`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          title: newNotificationData.title,
          description: newNotificationData.description,
        }),
      });
      // eslint-disable-next-line no-console
      if (NotificationResponse.status === 200) {
        const args = {
          message: 'Notification sending processed',
          description: 'Your new notification sent to users.',
          duration: 0,
        };
        notification.open(args);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <>
      <Divider orientation="left">Send Notification to Users</Divider>
      <Form layout="vertical" name="newNotification" onFinish={sendNotification} scrollToFirstError>
        <Form.Item
          name="title"
          label={<span>Title</span>}
          rules={[
            {
              required: true,
              message: 'Please enter notification title',
              whitespace: true,
            },
            () => ({
              validator(_, value) {
                if (!value || value.length >= 4) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Title should be more than 4 chracters'));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please enter notification title',
              whitespace: true,
            },
            () => ({
              validator(_, value) {
                if (!value || value.length >= 8) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Title should be more than 8 chracters'));
              },
            }),
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={isProcessing}
            type="primary"
            shape="round"
            size="large"
            htmlType="submit"
          >
            Send Notification
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default NotificationForm;
