/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import '../../App.css';
import { Row, Col, Button, Result, Progress, Steps, Divider, Tabs, Card, Modal } from 'antd';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import AppLayout from '../AppLayout';
import { useAuth } from '../Auth/AuthProvider';
import logo from '../../assets/logo-388x153.png';
import AdminCourse from '../AdminCourse';
import CurriculumList from '../CurriculumList';
import StudentExamList from '../Student/StudentExamList';

const { TabPane } = Tabs;

const { Step } = Steps;

const steps = [
  {
    title: 'Course Details',
  },
  {
    title: 'Payment',
  },
  {
    title: 'Success',
  },
];

const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;

function PremiumCourses() {
  const { id } = useParams();
  const { MakeRequest, AuthState } = useAuth();
  const { role } = AuthState;
  const getCourseRequest = MakeRequest('GET', `/user/course/show/${id}`);

  const { data: { data = {}, type } = {} } = useSWR(`/user/course/show/${id}`, getCourseRequest);

  const { course = {} } = data;
  const [isProcessing, setIsProcessing] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [boughtCourse, setBoughtCourse] = useState(null);

  const [current, setCurrent] = React.useState(0);

  const product = {
    amount: course.cost,
    course_id: course.id,
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  if (!type) {
    return <AppLayout>Please wait..</AppLayout>;
  }

  if (type === 'error') {
    return <AppLayout>Could not find the course</AppLayout>;
  }

  const createOrderId = async () => {
    const createOrderIdRequest = MakeRequest('POST', `/payment/create/orderId`);
    const res = await createOrderIdRequest({
      price: course.cost,
      currency: 'INR',
    });
    return res.data.orderId;
  };

  const razorPayPoUp = orderId => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: product.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: 'Officers Adda',
      description: 'Transaction',
      image: { logo },
      order_id: orderId,
      handler: async response => {
        if (!response.razorpay_signature) {
          showModal();
        } else {
          setIsProcessing('processing');

          const res = {
            transaction_id: {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              order_id: orderId,
            },
            amount: product.amount,
            course_id: course.id,
            email: 'test@test.com',
          };
          const premiumCourseEnrollRequest = MakeRequest(
            'POST',
            `/user/premium/course/enroll/create`
          );
          const enrollResponse = await premiumCourseEnrollRequest(res);
          setBoughtCourse(enrollResponse.data.response);
          setIsProcessing('success');
          next();
        }
      },
      prefill: {
        // name: 'DCentMass',
        // email: 'test@test.com',
        // contact: '9999999999',
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
        // setIsProcessing(false);
      });

      rzp1.open();
    }
  };
  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    await razorPayPoUp(orderId);
  };

  if (visible) {
    return (
      <>
        <Modal title="Payment Error" visible={visible} onCancel={handleCancel} footer={null}>
          <Result
            status="error"
            title="Transaction Error"
            subTitle="Payment processing by partner bank"
          />
        </Modal>
      </>
    );
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <div className="card-container">
              <Tabs type="card">
                <TabPane tab="course" key="1">
                  <Row gutter={[16, 8]} style={{ margin: '0px 0px 0px' }}>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                      <iframe
                        width="100%"
                        height="400px"
                        src={course.youtube_embed_code}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={8}
                      style={{ overflowWrap: 'break-word' }}
                    >
                      <h3>
                        Starts on: &nbsp;
                        {moment(course.start_date).format('Do MMM YY hh:mm A')}
                      </h3>
                      <h3>
                        Duration: &nbsp;
                        {course.no_of_days}
                        &nbsp; hrs
                      </h3>

                      <h3>
                        Description: &nbsp;
                        {course.description}
                      </h3>
                      <h3>
                        Cost: &nbsp;
                        {course.cost}
                        &nbsp;Rs
                      </h3>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Syllabus" key="2">
                  {course.curriculums.length ? (
                    <CurriculumList course={course} />
                  ) : (
                    <Divider orientation="left">No Syllabus found</Divider>
                  )}
                </TabPane>
                <TabPane tab="Downloads" key="3">
                  {course.syllabus ? (
                    <>
                      <Divider orientation="left">Syllabus Downloads </Divider>

                      <p>You will be able to download after enrolling to the course.</p>
                    </>
                  ) : (
                    <Divider orientation="left">Syllabus Downloads Coming Soon</Divider>
                  )}
                  {course.previous_question_papers ? (
                    <>
                      <Divider orientation="left">Previous Question Paper Downloads </Divider>

                      <p>You will be able to download after enrolling to the course.</p>
                    </>
                  ) : (
                    <Divider orientation="left">
                      Previous Question Papers Downloads Coming Soon
                    </Divider>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <Row gutter={[16, 8]} style={{ margin: '0px 0px 0px' }}>
              <Col xs={24} sm={24} md={24} lg={16} xl={16} style={{ padding: '4px 8px' }}>
                {isProcessing === 'processing' ? (
                  <>
                    <Divider orientation="left">Please Wait... Processing your purchase</Divider>
                    <Progress percent={70} status="active" />
                  </>
                ) : (
                  <>
                    <Card>
                      <Divider orientation="left">Confirm Details</Divider>
                      <h3>
                        Course:&nbsp;
                        {course.title}
                      </h3>
                      <h3>Cost:&nbsp; ₹{course.cost}</h3>

                      <h3>Total Amount:&nbsp; ₹{course.cost}</h3>
                      <br />
                      <Button
                        type="primary"
                        shape="round"
                        id="rzp-button1"
                        onClick={handleRazorPayPayment}
                      >
                        Click to Pay
                      </Button>
                    </Card>
                  </>
                )}
              </Col>
            </Row>
          </>
        );
      case 2:
        return (
          <>
            <Result
              status="success"
              title={`Enrolled to premium course ${course.title}`}
              subTitle={`Your transaction id is ${boughtCourse.payment.transaction_id}`}
            />
          </>
        );

      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <AppLayout>
        <Divider orientation="left">Course: {course.title}</Divider>
        {role === 'admin' || role === 'instructor' ? (
          <AdminCourse course={course} />
        ) : course.isEnrolled ? (
          <>
            <h1 style={{ color: '#eb2f96' }}>
              Streams become active once the Live Teaching starts
            </h1>
            <div className="card-container">
              <Tabs type="card">
                <TabPane tab="Course Video Updates" key="1">
                  <Row gutter={[16, 8]} style={{ margin: '0px 0px 0px' }}>
                    <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                      <div className="event-embed-container">
                        <div className="even-embed-iframe-div">
                          <iframe
                            title="test"
                            src={course.event_embed_code}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            className="even-embed-iframe"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                      <div className="chat-embed-container">
                        <iframe
                          title="test"
                          src={course.chat_embed_code}
                          width="100%"
                          height="100%"
                          frameBorder="0"
                        />
                      </div>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Syllabus" key="2">
                  {course.curriculums.length ? (
                    <CurriculumList course={course} />
                  ) : (
                    <Divider orientation="left">No Syllabus found</Divider>
                  )}
                </TabPane>
                <TabPane tab="Downloads" key="3">
                  {course.syllabus ? (
                    <>
                      <Divider orientation="left">Syllabus Downloads </Divider>

                      <Button type="primary" shape="round">
                        Download Syllabus for&nbsp;
                        {course.title}
                      </Button>
                    </>
                  ) : (
                    <Divider orientation="left">Syllabus Downloads Coming Soon</Divider>
                  )}
                  {course.previous_question_papers ? (
                    <>
                      <Divider orientation="left">Previous Question Paper Downloads </Divider>

                      <Button type="primary" shape="round">
                        Download Previous Question Papers for&nbsp;
                        {course.title}
                      </Button>
                    </>
                  ) : (
                    <Divider orientation="left">
                      Previous Question Papers Downloads Coming Soon
                    </Divider>
                  )}
                </TabPane>
                <TabPane tab="Exams" key="4">
                  {course.exams ? (
                    <>
                      <Divider orientation="left">Exams</Divider>
                      <StudentExamList exams={course.exams || []} />
                    </>
                  ) : (
                    <Divider orientation="left">Exams for the course will be found here </Divider>
                  )}
                </TabPane>
                {course.curriculums.length
                  ? course.curriculums.map((singleCurriculum, index) => {
                      return (
                        <>
                          <TabPane tab={`${singleCurriculum.title} Live`} key={index + 1 + 4}>
                            <Row gutter={[16, 8]} style={{ margin: '0px 0px 0px' }}>
                              <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                                <div className="event-embed-container">
                                  <div className="even-embed-iframe-div">
                                    <iframe
                                      title="test"
                                      src={singleCurriculum.event_embed_code}
                                      frameBorder="0"
                                      allow="autoplay; fullscreen; picture-in-picture"
                                      allowFullScreen
                                      className="even-embed-iframe"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                                <div className="chat-embed-container">
                                  <iframe
                                    title="test"
                                    src={singleCurriculum.chat_embed_code}
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                        </>
                      );
                    })
                  : null}
              </Tabs>
            </div>
          </>
        ) : (
          <>
            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{getStepContent(current)}</div>
            <div className="steps-action">
              {current === 1 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Previous
                </Button>
              )}
              {current === 0 && (
                <Button type="primary" onClick={() => next()}>
                  Next
                </Button>
              )}
            </div>
          </>
        )}
      </AppLayout>
    </>
  );
}

export default PremiumCourses;
