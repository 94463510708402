import { DeleteFilled } from '@ant-design/icons';
import { Button, Card, Divider, List, Modal, Popconfirm, Tooltip } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from './Auth/AuthProvider';

function updateCourseCurriculum(course, curriculumId, mutateFunction) {
  const updatedCourse = course.curriculums.filter(singleCurriculum => {
    return singleCurriculum.id !== curriculumId;
  });

  mutateFunction(updatedCourse);
}

function CurriculumList(props) {
  const { course, role, mutateCourse } = props;
  const { MakeRequest } = useAuth();
  const history = useHistory();

  const deleteCurriculum = async (id, title) => {
    const deleteCurriculumRequest = MakeRequest('DELETE', `/delete/curriculum/${id}`);
    await deleteCurriculumRequest();
    updateCourseCurriculum(course, id, mutateCourse);
    Modal.success({
      content: `Subject ${title} Deleted`,
    });
  };

  const displayTopics = topics => {
    return (
      <>
        <Divider orientation="left">Topics</Divider>
        <Card>
          <List
            itemLayout="horizontal"
            dataSource={topics}
            renderItem={(topic, index) => (
              <List.Item>
                <List.Item.Meta title={`${index + 1}. ${topic.title}`} />
              </List.Item>
            )}
          />
        </Card>
      </>
    );
  };

  return (
    <>
      {course.curriculums.length ? (
        <>
          <Divider orientation="left">Syllabus</Divider>
        </>
      ) : (
        <>
          <Divider orientation="left">No Syllabus found</Divider>
        </>
      )}

      {course.curriculums.map((curriculum, index) => (
        <Card
          title={`${index + 1}. ${curriculum.title}`}
          key={curriculum.id}
          extra={
            role === 'admin' || role === 'instructor' ? (
              <>
                <>
                  <Button
                    shape="round"
                    className="viewBtnStyle"
                    onClick={() => history.push(`/curriculum/${curriculum.id}`)}
                  >
                    View
                  </Button>
                  &nbsp;
                  <Tooltip title={`Delete Subject ${curriculum.title}`} placement="right">
                    <Popconfirm
                      title="Topics Associated with this Subject will be deleted too. Sure to delete?"
                      onConfirm={() => deleteCurriculum(curriculum.id, curriculum.title)}
                    >
                      <Button shape="round" className="deleteBtnStyle">
                        <DeleteFilled />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </>
              </>
            ) : null
          }
        >
          {curriculum.topics.length ? (
            displayTopics(curriculum.topics)
          ) : (
            <Divider orientation="left">
              No Topics found in &nbsp;
              {curriculum.title}
            </Divider>
          )}
        </Card>
      ))}
    </>
  );
}

export default CurriculumList;
