import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Modal,
  Button,
  Typography,
  Space,
  Col,
  Row,
  Radio,
  Card,
  Tooltip,
} from 'antd';
import {
  SmileOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import { useAuth } from '../Auth/AuthProvider';
import notify from '../../utils/Notify';

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [visible]);
};

const ModalForm = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  useResetFormOnCloseModal({
    form,
    visible,
  });

  const onOk = () => {
    form.submit();
  };

  return (
    <Modal title="Create Question" visible={visible} onOk={onOk} onCancel={onCancel} width={1000}>
      <Form form={form} layout="vertical" name="questionForm">
        <Form.Item
          name="question"
          label="Question"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.List
          name="options"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  label={index === 0 ? 'Options' : ''}
                  required={false}
                  key={field.key}
                >
                  <Row gutter={{ xs: 7, sm: 7, md: 24, lg: 24 }}>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                      <Form.Item
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        label="option"
                        name={[field.name, 'optionText']}
                        fieldKey={[field.fieldKey, 'optionText']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input option or delete this field.',
                          },
                        ]}
                        noStyle
                      >
                        <Input placeholder={field.optionText} style={{ width: '60%' }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                      <Form.Item
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        name={[field.name, 'isCorrect']}
                        fieldKey={[field.fieldKey, 'isCorrect']}
                        rules={[{ required: true, message: 'Missing Correct or Wrong' }]}
                      >
                        <Radio.Group>
                          <Radio value>Correct</Radio>
                          <Radio value={false}>Wrong</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  Add options
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

function updateExam(exam, questions, mutateFunction) {
  const updatedExamwithQuestions = { ...exam.questions, questions };
  mutateFunction(updatedExamwithQuestions);
}

function AddQuestionsFormWithModal(props) {
  const { exam, mutateExam, handleCloseMain } = props;
  const [mainForm] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [QuestionsValues, setQuestionsValues] = useState([]);
  // const [isProcessing, setIsProcessing] = useState(false);
  const [addNewQuestion, setAddNewQuestion] = useState('');
  const { MakeRequest } = useAuth();

  const showQuestionModal = () => {
    setVisible(true);
  };

  const hideQuestionModal = () => {
    setVisible(false);
  };

  const onFinish = async questionData => {
    // eslint-disable-next-line no-console
    console.log(questionData);
    if (!QuestionsValues.length) {
      Modal.error({
        content: `Please provide at least one Question and for each Question.`,
      });
    } else {
      const formattedQuestions = QuestionsValues.map(question => {
        return {
          ...question,
          exam_id: exam.id,
        };
      });

      const data = {
        questions: formattedQuestions,
        exam_id: exam.id,
      };
      const AddNewQuestion = MakeRequest('POST', '/questions/create');

      // setIsProcessing(true);
      setAddNewQuestion(exam.title);
      const response = await AddNewQuestion(data);
      if (response.type === 'error') {
        // eslint-disable-next-line no-restricted-syntax
        for (const [error] of Object.values(response.data)) {
          notify({ type: 'error', message: error });
        }
        // setIsProcessing(false);
      } else if (response.type === 'success') {
        handleCloseMain();

        Modal.success({
          content: `Questions Created for ${addNewQuestion}`,
        });
        mainForm.setFieldsValue({ questions: [] });
        setQuestionsValues([]);
        updateExam(exam, response.data, mutateExam);
      }
    }
  };

  // function onChange(checkedValues) {
  //   const questions = mainForm.getFieldValue('questions');
  //   // eslint-disable-next-line array-callback-return
  //   const formattedQuestions = questions.map(question => {
  //     const filtered = question.options.map(singleValue => {
  //       if (checkedValues.includes(singleValue)) {
  //         return { isCorrect: true, optionText: singleValue };
  //       }
  //       return { isCorrect: false, optionText: singleValue };
  //     });
  //     return {
  //       ...question,
  //       options: filtered,
  //     };
  //   });

  //   setQuestionsValues(formattedQuestions);
  // }

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Provider
            onFormFinish={(name, { values, forms }) => {
              if (name === 'questionForm') {
                const { addQuestionForm } = forms;
                const questions = addQuestionForm.getFieldValue('questions') || [];
                addQuestionForm.setFieldsValue({
                  questions: [...questions, values],
                });
                setVisible(false);
                setQuestionsValues([...QuestionsValues, values]);
              }
            }}
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Form form={mainForm} name="addQuestionForm" onFinish={onFinish}>
              <Form.Item
                label="Questions List"
                // name="userList"
                shouldUpdate={
                  (prevValues, curValues) => prevValues.questions !== curValues.questions
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                {({ getFieldValue }) => {
                  const questions = getFieldValue('questions') || [];
                  return questions.length ? (
                    <>
                      <ol>
                        {questions.map((question, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={index} className="question">
                            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                            <Space direction="vertical">
                              {question.question}
                              <Card>
                                {question.options.map(singleOption => {
                                  return singleOption.isCorrect ? (
                                    <>
                                      <p>
                                        <Tooltip
                                          title={`Correct Answer ${singleOption.optionText}`}
                                          placement="right"
                                        >
                                          {singleOption.optionText}
                                          &nbsp;
                                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                                        </Tooltip>
                                      </p>
                                    </>
                                  ) : (
                                    // `${x + 1}. ${option.optionText}: ${option.isCorrect}`
                                    <>
                                      <p>
                                        <Tooltip
                                          title={`Wrong Answer ${singleOption.optionText}`}
                                          placement="right"
                                        >
                                          {singleOption.optionText}
                                          &nbsp;
                                          <CloseCircleTwoTone twoToneColor="#eb2f96" />
                                        </Tooltip>
                                      </p>
                                    </>
                                  );
                                })}
                              </Card>
                            </Space>
                          </li>
                        ))}
                      </ol>
                    </>
                  ) : (
                    <Typography.Text className="ant-form-text" type="secondary">
                      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                      ( <SmileOutlined /> No Questions yet. )
                    </Typography.Text>
                  );
                }}
              </Form.Item>

              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Form.Item {...tailLayout}>
                <Button
                  type="dashed"
                  htmlType="button"
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={showQuestionModal}
                >
                  Add Question
                </Button>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            </Form>

            <ModalForm visible={visible} onCancel={hideQuestionModal} />
          </Form.Provider>
        </Col>
      </Row>
    </>
  );
}

export default AddQuestionsFormWithModal;
