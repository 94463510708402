import React from 'react';
import { Button, Modal } from 'antd';
import AddQuestionsFormWithModal from './AdminDashboard/AddQuestionsFormWithModal';

function CreateNewQuestionsForm(props) {
  const { exam, mutateExam, buttonText } = props;

  const [visible, setVisible] = React.useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button
        variant="contained"
        className="topicBtnStyle"
        color="primary"
        shape="round"
        onClick={handleOpen}
      >
        {buttonText}
      </Button>
      <Modal
        title="Add Questions"
        visible={visible}
        onCancel={handleClose}
        footer={null}
        width={1000}
      >
        <div>
          <AddQuestionsFormWithModal
            exam={exam}
            mutateExam={mutateExam}
            handleCloseMain={handleClose}
          />
        </div>
      </Modal>
    </>
  );
}

export default CreateNewQuestionsForm;
