/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './Auth/AuthProvider';

const getPageMeta = path => {
  switch (path) {
    case '/':
      return {
        pageHeading: 'Dashboard',
        pageTitle: 'Dashboard | Officers Adda',
        activePage: 'dashboard',
      };

    case '/logout':
      return {
        pageHeading: 'Logging out.. Please wait',
        pageTitle: 'Logging out | Officers Adda',
        activePage: 'logOut',
      };

    case '/profile':
      return {
        pageHeading: 'Profile',
        pageTitle: 'Profile | Officers Adda',
        activePage: 'profile',
      };

    case '/manage-classes':
      return {
        pageHeading: 'Manage classes',
        pageTitle: 'Manage classes | Officers Adda',
        activePage: 'manage-classes',
      };

    case '/manage-exams':
      return {
        pageHeading: 'Manage exams',
        pageTitle: 'Manage exams | Officers Adda',
        activePage: 'manage-exams',
      };

    case '/premium-course':
      return {
        pageHeading: 'Premium Courses',
        pageTitle: 'Premium Courses | Officers Adda',
        activePage: 'premium-course',
      };

    case '/premium-exam':
      return {
        pageHeading: 'Premium exam',
        pageTitle: 'Premium exam | Officers Adda',
        activePage: 'premium-exam',
      };

    case '/free-course':
      return {
        pageHeading: 'Free course',
        pageTitle: 'Free course | Officers Adda',
        activePage: 'free-course',
      };

    case '/exam':
      return {
        pageHeading: 'Free exam',
        pageTitle: 'Free exam | Officers Adda',
        activePage: 'exam',
      };

    case '/settings':
      return {
        pageHeading: 'Manage settings',
        pageTitle: 'Manage settings | Officers Adda',
        activePage: 'settings',
      };

    case '/course/:id':
      return {
        pageHeading: 'course',
        pageTitle: 'course | Officers Adda',
        activePage: 'course',
      };

    case '/curriculum/:id':
      return {
        pageHeading: 'course curriculum',
        pageTitle: 'course curriculum | Officers Adda',
        activePage: 'curriculum',
      };

    case '/topic/:id':
      return {
        pageHeading: 'curriculum topic',
        pageTitle: 'curriculum topic | Officers Adda',
        activePage: 'topic',
      };

    default:
      // eslint-disable-next-line no-console
      console.error("No man's land. Invalid navigation attempt.");
      break;
  }
  return {
    pageHeading: '',
    pageTitle: '',
    activePage: '',
  };
};

function ProtectedRoute({ component: Component, auth, ...rest }) {
  const { AuthState } = useAuth();
  return (
    <Route
      {...rest}
      render={props => {
        if (AuthState.isLoggedIn) {
          return (
            <main>
              <Component pageMeta={getPageMeta(rest.path)} {...props} />
            </main>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
}

export default ProtectedRoute;
