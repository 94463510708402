import React from 'react';
import { Divider, Layout } from 'antd';
import Sidebar from './Sidebar';
import Head from './Head';

const { Content, Footer } = Layout;

function AppLayout({ children }) {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Head />
        <Layout className="site-layout">
          <Sidebar />

          <Layout>
            <Content className="layout-content-margin main-content-div">{children}</Content>
            <Footer style={{ textAlign: 'center' }} className="layout-content-margin">
              <h4>
                <a href="https://www.officersadda.com/p/privacy-policy.html?m=1"> Privacy Policy</a>
                &nbsp;/&nbsp;
                <a href="https://www.officersadda.com/p/cancellationrefund-policy.html?m=1">
                  Cancellation Policy
                </a>
              </h4>
              <Divider orientation="center">
                Officers Adda &copy;
                {new Date().getFullYear()}
              </Divider>
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
}

export default AppLayout;
